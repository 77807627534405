/*
Project:   Template
CSS Type:  Semantic
Version:   1.2.0 (17.04.2014)
Authors:   Grigory Zarubin, Alexander Rubanov, Alexey Efimov (Shogo.RU)
*/

@import "_fonts";
@import "_utils";
@import "_jquery.fancybox";
//@import "alertify/alertify.core.css";
/* @import "_mixins"; */
@import "_const";
@import "_resets";
@import "_common_styles";
@import "_jquery.jcarousel";
@import "_perfect-scrollbar";
@import "_stylish-select";
@import "_jquery-ui";
@import "_video-js";
@import "_social-likes";
@import "_jquery.tosrus.all";
@import "_news";
@import "_print";

/*   -=========== HTML5 structure ===========-   */
#structure {
  position: relative;
  @media $not-mobile {
    min-height: 100%;
  }
}
.wrapper {
  padding: 0 10px;
  max-width: $wrapper-max-width;
  /* min-width: $wrapper-min-width; */
  margin-left: auto;
  margin-right: auto;
  position: relative; }
$mobile-header-height = 58px;
header {
  /* overflow: hidden; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  background: $white;
  text-align: center;
  @media $small-handheld {
    text-align: left;
    box-shadow: 0 3px 5px rgba(#000, .09);
  }
  .wrapper {
    height: $header-height;
    line-height: $header-height;
    @media (max-width: 1190px) {
      .guarantee {
        display: none;
      }
    }
    @media (max-width: 812px) {
      height: $mobile-header-height;
      line-height: $mobile-header-height;
      padding: 0 10px;
    }
    @media only screen and (max-width: 812px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    @media (max-width: 480px) {
      gap: 10px;
    }
  }
}
#content {
  padding-top: $header-height;
  padding-bottom: $footer-height;
  /* @media only screen and (max-width: 1130px) {
    padding-bottom: $footer-max-height; } */
  @media only screen and (max-width: 1130px) {
    padding-bottom: 260px;
  }
  @media $small-handheld {
    padding-bottom: 0;
    padding-top: 76px;
  }
}
aside#left {
  float: left;
  width: 230px; }
section#main {
  float: left;
  width: 730px;
  padding-left: 30px; }
footer {
  clear: both;
  position: relative;
  margin-top: - $footer-height;
  height: auto;
  background: url(/i/footer-bg.jpg);
  @media only screen and (max-width: 1130px) {
    /* margin-top: - $footer-max-height;
    height: $footer-max-height;  */
    margin-top: -260px;
    height: auto;
  }
  @media $small-handheld {
    margin-top: 0;
    height: auto;

    .wrapper {
      padding: 0;
    }
  }
}

/*   -=========== Blocks ===========-   */
.hd-logo {
  float: left;
  img {
    margin-top: -3px;
    @media $handheld {
      @media $not-mobile {
        max-width: 150px;
      }
    }
  }

  @media (max-width: 1190px) {
    margin-right: 15px;
  }

  @media $mobile {
    width: 30px; height: 39px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    float: none;
    background: url(/i/status-logo.svg) no-repeat 50% / contain;

    img {
      /* height: 100%;
      vertical-align: top;
      margin: 0; */
      display: none;
    }
  }
}

.hd-whatsapp {
  width: 28px;
  height: @width;
  display: block;
  background-image: url('/i/hd-whatsapp.png');
  background-size: contain;
}

.hd-telegram {
  width: 23px;
  height: @width;
  display: block;
  background-image: url('/i/hd-telegram.png');
  background-size: contain;
}

.hd-phone {
  float: right;
  font-size: 10px;
  line-height: 100%;
  padding: 0 8px;
  margin-top: 11px;
  position: relative;
  margin-left: 133px;
  color: #888;
  text-align: left;
  @media only screen and (max-width: 812px) {
    /* margin-left: 0;
    margin-right: 45px; */
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    float: none;
    display: inline-block;
    vertical-align: middle;
    padding: 0 4px;
    text-align: center;
    position: relative;
    left: 0;
    width: calc(100% - 260px);
    max-width: 555px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-right: 120px;
    box-sizing: border-box;
    margin-top: 0;
    padding-top: 7px;
  }

  @media (max-width: 812px) {
    br {
      display: none;
    }
  }

  @media (max-width: 700px) {
    font-size: 9px;
    width: calc(100% - 105px);
    margin-top: -4px;
  }

  @media $mobile {
    white-space: nowrap;
  }

  @media (max-width: 360px) {
    padding-left: 0;
    padding-right: 45px;
  }

  @media (max-width: 320px) {
    font-size: 7px;
    white-space: nowrap;
    margin-top: -10px;
  }

  @media only screen and (max-width: 480px) {
    text-align: left;
    width: calc(100% - 88px);

    br {
      display: block;
    }
  }

  .phone-number {
    display: block;
    color: $black;
    font-family: Arial;
    font-size: 16px;
    // font-weight: bold;
    line-height: 1.2;
    margin-bottom: 3px;
    @media only screen and (max-width: 980px) {
      font-size: 14px;
    }
    @media (max-width: 812px) {
      font-size: 18px;
      margin-bottom: 5px;
    }
    @media $small-mobile {
      font-size: 12px;
    }
    @media (max-width: 320px) {
      font-size: 9px;
    }

    &:nth-child(2) {
      @media only screen and (max-width: 480px) {
        display: none;
      }
    }
  }
  .worktime {
    position: absolute;
    margin-top: 20px;
    background-color: lightgrey;
    margin-left: -10px;
    width: calc(100% + 4px);
    margin-top: 6px;
  }
}

.hd-socials {
  display: flex;
  gap: 10px;
  margin-top: 5px;
  align-items: center;
}
.hd-links {
  position: absolute;
  top: 1px;
  right: 100%;
  margin-right: 10px;
  display: flex;
  gap: 10px;
  align-items: center;

  @media (max-width: 812px) {
    /* right: auto;
    left: 100%; */
    right: 0;
    margin-right: 0;
    /* margin-right: 0; */
    /* margin-left: 8px; */
  }
  @media (max-width: 367px) and (min-width: 321px) {
    top: 10px;
  }

  @media only screen and (max-width: 480px) {
    top: auto;
  }
  .icon-letter {
    width: 44px;
    height: 44px;
    /* border-color: #dddbdb; */
    /* &:not(:hover) {
      background: url(/i/icon-letter-black.png) no-repeat 50%;
    } */
    background-position: 3px -41px !important;
    border-color: $yellow !important;
    /* &:hover {
      background-position: 3px -41px;
    } */
    &:hover {
      opacity: .75;
    }
    @media (max-width: 812px) {
      width: 35px; height: 35px;
      background: url(/i/icon-letter-black.svg) no-repeat 50% / auto 25px !important;
    }
  }
}
@media only screen and (max-width: 812px) {
  .mobile-menu-link {
    display: inline-block;
    vertical-align: middle;
    width: 39px; height: 39px;
    background: #55575b;
    position: relative;

    .bars {
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);

      &, &:before, &:after {
        display: block;
        width: 25px; height: 2px;
        background: $white;
        position: absolute;
        transition: all .25s ease;
      }

      &:before, &:after {
        content: '';
        position: absolute;
        left: 0;
      }

      &:before {
        bottom: 6px;
      }

      &:after {
        top: 6px;
      }
    }

    &.is-active {
      background: $yellow;

      &:before, &:after {
        content: '';
        display: block;
        background: $yellow;
        position: absolute;
      }

      &:before {
        left: 100%; top: 0;
        width: 10px; height: 100%;
        padding-bottom: 10px;
      }

      &:after {
        left: 0; top: 100%;
        width: 100%; height: 10px;
      }

      .bars {
        background: transparent;

        &:before, &:after {
          margin-left: -6px;
        }

        &:before {
          transform: rotate(-45deg);
          left: 50%; bottom: 0;
          margin-bottom: -5px;
        }

        &:after {
          transform: rotate(45deg);
          left: 50%; top: 0;
          margin-top: 5px;
        }
      }
    }
  }
}

.index-rotator {
  white-space: nowrap;
  position: relative;
  width: 100%;
  > a {
    display: inline-block;
    width: 100%;
    text-align: center;

    img {
      max-width: 100%;
      min-height: 150px;
      height: auto;
      object-fit: cover;
    }
  }
  /*
  .cycle-pager-wrapper {
    width: 100%;
    position: absolute;
    bottom: 15px;
    left: 0;
    z-index: 600; }

  .cycle-pager {
    text-align: justify;
    line-height: 0;
    font-size: 0;
    &:after {
      content: '';
      display: inline-block;
      width: 100%; }
    a {
      display: inline-block;
      vertical-align: bottom;
      width: 10%;
      height: 70px;
      line-height: 70px;
      background: rgba(#000, .75);
      text-decoration: none;
      text-transform: uppercase;
      font-family: Arial;
      font-size: 18px;
      color: $white;
      text-align: center;
      padding-bottom: 0;
      vertical-align: bottom;
      transition: all .2s ease;
      &.cycle-pager-active {
        background: rgba($yellow, .75); }
      &.cycle-pager-active, &:hover {
        padding-bottom: 8px; }
      @media only screen and (max-width: 1100px) {
        font-size: 22px; }
      @media only screen and (max-width: 820px) {
        font-size: 12px; }
      &:last-child {
        font-size: 14px;
        @media only screen and (max-width: 1200px) {
          font-size: 16px; }
        @media only screen and (max-width: 1100px) {
          font-size: 14px; }
        @media only screen and (max-width: 930px) {
          font-size: 12px; }
        @media only screen and (max-width: 790px) {
          font-size: 11px; }
      }
    }
  }
  */


  .cycle-pager-wrapper {
    position: absolute;
    width: 100%;
    z-index: 600;

    @media $desktop{
      bottom: 50px;
    }
    @media $handheld{
      width: calc(100% - 24px);
      margin-left: 10px;
      bottom: 10%;
    }
    @media $small-mobile{
      width: 100%;
      margin-left: 0;
      bottom: 0;
    }
    .cycle-pager{
      box-sizing: border-box;
      background-color: rgba(0,0,0,.5);
      padding: 32px 0px;
      height: 90px;
      text-align: center;

      @media $handheld{
        padding: 24px 16px;
      }
      @media $small-mobile{
        padding: 2px 16px;
        height: auto;
      }
    }


    a {
      box-sizing: content-box;
      font-size: 0;
      line-height: 36px;
      color: #fff;
      padding: 24px 16px;
      text-decoration: none;
      transition: 0.3s;

      @media $small-mobile{
        padding: 24px 10px;
      }

      &:before {
        position absolute;
        margin-left: -18px;
        margin-top: 16px;
        content: '';
        z-index: 666;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid #fff;
        background: transparent;
        transform: translate(-50%,-50%);

        @media $handheld{
          margin-left: -16px;
          width: 8px;
          height: 8px;
        }
        @media $small-mobile{
          margin-left: 0px;
          width: 6px;
          height: 6px;
        }
      }
      &:after{
        //outline: 1px solid red;
        //background-color: rgba(255,0,0,.33);
        position absolute;
        content: '';
        width: 36px;
        height: 36px;
        transform: translate(-25%,-25%);
        margin-left: -28px;
        margin-top: 6px;

        @media $handheld{
          margin-left: -24px;
          width: 36px;
          height: 36px;
        }
        @media $small-mobile{
          margin-left: -5px;
          margin-top: 10px;
          width: 24px;
          height: 24px;
        }
      }

      &:hover:not(:focus) {

        &:before {
          @media $not-small-handheld{
            background: #fff;
          }
        }
      }

      &.cycle-pager-active{
        color: $yellow;
        @media $not-mobile {
          font-size: 16.5px;
        }
        &:before {
          border-color: $yellow !important;
          background-color: $yellow !important;
        }
      }
    }
  }

}

.homepage-mobile-collections {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 10px;
  margin-bottom: 10px;

  a {
    display: block;
    width: calc(50% - 5px);
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      position: relative;
      background: rgba(#000, .5);
      z-index: 2;
      padding-bottom: 60%;
    }

    img {
      height: 100%;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    span {
      position: absolute;
      top: 50%; left: 0;
      transform: translateY(-50%);
      z-index: 3;
      text-align: center;
      width: 100%;
      font-size: 24px;
      color: $yellow;
      text-transform: uppercase;
    }

    &:last-child {
      width: 100%;

      &:before {
        padding-bottom: 30%;
      }
    }
  }
}

@media $mobile {
  .mobile-caption,
  .mobile-caption a {
    text-align: center;
    font-size: 23px;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 17px;
  }
}

.video {
  padding-bottom: 56.5%;
  position: relative;

  iframe {
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100% !important;
  }
}

.about-block {
  .text-container {
    float: right;
    width: 405px; }
  .index-top-video-block {
    margin-right: 420px; }
  @media only screen and (max-width: 900px) {
    .text-container {
      float: none;
      width: 100%; }
    .index-top-video-block {
      margin-right: 0; }
  }
  @media $mobile {
    .text-container {
      font-size: 14px;
      line-height: 1.35;
    }
  }
}

.twitter-block {

}

.features-list {
  text-align: justify;
  line-height: 0;
  font-size: 0;
  position: relative;
  .feature, .feature-dummy {
    display: inline-block;
    vertical-align: top;
    width: 32%;
    margin-bottom: 15px;
    @media only screen and (max-width: 900px) {
      width: calc(50% - 10px); }
    @media only screen and (max-width: 600px) {
      width: 100%; }
    @media $mobile {
      padding-right: 10px;
    }
    > div {
      height: 100%;
      position: relative;
      transition: all .6s ease;
      transform-style: preserve-3d;
      perspective: 1000; }
    .front, .back {
      display: block;
      width: 100%;
      height: 100%;
      backface-visibility: hidden; }
    .back {
      position: absolute;
      top: 0;
      left: 0;
      background: $yellow;
      color: $white;
      box-sizing: border-box;
      padding: 20px 30px;
      font-size: 18px;
      line-height: 120%;
      text-align: left;
      html.mobile & {
        opacity: 0;
      }
    }
    .front {
      z-index: 2;
      transform: rotateY(0deg); }
    .back {
      html:not(.mobile) & {
        transform: rotateY(180deg);
      }
    }
    img {
      max-width: 100%; }
    .inner {
      box-sizing: border-box;
      display: block;
      width: 270px;
      height: 100px;
      line-height: 100px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -50px 0 0 -135px;
      background: rgba(#000, .75);
      text-align: center;
      padding: 0 10px;
      @media $mobile {
        width: 192px;
        height: 64px;
        line-height: 64px;
        margin: 0;
        transform: translate(-50%, -50%);
      }
      span {
        display: inline-block;
        vertical-align: middle;
        line-height: 120%;
        font-size: 20px;
        color: $yellow;
        text-transform: uppercase;
        @media $mobile {
          font-size: 13px;
        }
      }
    }
    html:not(.mobile) & {
      &:hover {
        > div {
          transform: rotateY(180deg); }
      }
    }
    .more-btn {
      background: $yellow;
      color: $black;
      height: 30px;
      line-height: 30px;
      border: 1px solid $black;
      padding: 0 10px;
      position: absolute;
      bottom: 12px;
      left: 50%;
      transform: translateX(-50%);
      html.mobile & {
        width: 100%; height: 100%;
        top: 0; left: 0;
        transform: none;
        box-sizing: border-box;
      }
    }
  }
  .feature-dummy {
    margin-bottom: 0; }
  &.inner-features {
    .feature {
      .inner {
        width: auto;
        height: auto;
        line-height: 40px;
        margin-left: 0;
        margin-top: 0;
        transform: translate(-50%, -50%); }
    }
  }
  &.materials {
    .feature {
      .more-btn {
        bottom: 25px; }
    }
  }
}

.features-top, .features-bottom {
  height: 50px;
  line-height: 50px;
  color: $white;
  background: $yellow;
  text-align: center;
  text-transform: uppercase;
  font-size: 35px;
  @media $portrait-tablet {
    font-size: 30px;
  }
  @media only screen and (max-width: 812px) {
    font-size: 25px;
  }
  @media $mobile {
    height: 31px;
    line-height: 31px;
    font-size: 13px;
  }
}

.materials-block {
  padding-top: 15px;
  background: url(/i/materials-bg.jpg); }

.planed-scheme {
  width: 523px;
  height: 207px;
  background: url(/i/scheme.png) no-repeat;
  position: relative;
  @media only screen and (max-width: 812px) {
    max-width: 50vw;
  }
  @media only screen and (max-width: 700px) {
    max-width: calc(100vw - 40px);
  }
  img {
    max-width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    opacity: 0;
    transition: opacity .3s ease;
    &.active {
      opacity: 1; }
  }
  &.planed-scheme-2 {
    width: 525px;
    height: 283px;
    background-image: url(/i/scheme-2.png?v=2);
    margin-right: 20px;
  }
  &.planed-scheme-3 {
    width: 680px;
    height: 348px;
    background-image: url(/i/scheme-3.png);
    background-size: contain;
    margin-right: 20px;
  }
  &.planed-scheme-straight {
    width: 607px; height: 672px;
    background-image: url(/i/scheme-straight-arrow.png);
    background-size: contain;
    background-position: 50%;
    @media $handheld {
      max-width: 100%;
    }
    @media (max-width: 812px) {
      max-width: 90vw;
    }
    img {
      top: 50%;
      transform: translateY(-50%);
      max-width: 100%;
      @media $small-mobile {
        transform: none;
        top: 0;
      }
    }
    @media $handheld {
      height: 608px;
    }
  }
  &.planed-scheme-back {
    width: 610px; height: 672px;
    background-image: url(/i/scheme-back-arrow.png);
    background-size: contain;
    background-position: 50%;
    @media $handheld {
      max-width: 100vw;
    }
    @media (max-width: 812px) {
      max-width: 90vw;
    }
    img {
      top: 50%;
      transform: translateY(-50%);
      max-width: 100%;
      @media $small-mobile {
        transform: none;
        top: 0;
      }
    }
  }
}
.planed-scheme-controls {
  position: relative;
  .btn {
    width: 162px;
    height: 37px;
    line-height: 37px;
    background: transparent;
    color: $black;
    font-size: 15px;
    font-weight: bold;
    border: 1px solid $black;
    text-transform: uppercase;
    margin: 6px 0;
    &:hover {
      background: $yellow;
      border-color: $yellow;
      color: $white; }
    @media $small-handheld {
      width: 95px;
      font-size: 11px;
      height: 24px;
      line-height: 24px;
    }
  }
  &.controls-2 {
    &, .btn {
     width: 210px;
    }
  }
  &.controls-3 {
    &, .btn {
     width: 210px;
    }
  }
  &.controls-back,
  &.controls-straight {
    margin-top: 30px;
    width: 580px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 812px) {
      max-width: 90vw;
    }
    @media $small-mobile {
      width: 100%;
      margin-top: 0;
    }
    .btn {
      font-size: 14px;
      width: 254px;
      margin-left: 7px;
      margin-right: 7px;
    }
    .btn:first-child {
      margin-left: 50px;
      margin-right: 50px;
      @media $small-mobile {
        margin-left: 7px;
        margin-right: 7px;
      }
    }
  }
}
.schemes-table {
  @media only screen and (max-width: 1150px) {
    img {
      height: 300px; }
  }
  @media only screen and (max-width: 1024px) {
    img {
      height: 265px; }
  }
  @media only screen and (max-width: 812px) and (min-width: 701px) {
    img {
      height: 210px;
    }
  }
}

.big-video-block {
  position: relative;
  width: 100%;
  padding-bottom: 51.9%;
  .video-js {
    position: absolute;
    left: 0;
    top: 0; }
}

.opened--btns .btn {
  min-width: 260px;
  margin: 10px;

  @media $mobile {
    min-width: 198px;
    height: 44px;
    line-height: 44px;
    font-size: 20px;
  }
}

.map-control-wrapper ul.newList {
  column-count: 2;
  max-height: unset;

  @media $small-mobile {
    column-count: 1;
  }
}

.map-block {
  position: relative;
  z-index: 10;
  img {
    max-width: 100%; }
  .btn {
    min-width: 260px;
    margin: 10px;
    @media $mobile {
      min-width: 198px;
      height: 44px;
      line-height: 44px;
      font-size: 20px;
    }
  }
  @media $mobile {
    overflow: hidden;
    img {
      max-width: none;
      width: 150%;
    }
  }
  @media $small-handheld {
    display: none;
  }
}
.map-block-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media $mobile {
    white-space: normal;
  }
}
.contacts-overlay {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  .status-icon {
    background: url(/i/contacts-icon-status.png) no-repeat 0 6px;
    padding-left: 35px; }
  .address-icon {
    background: url(/i/contacts-icon-address.png) no-repeat 0 4px;
    padding-left: 35px; }
  .phone-icon {
    background: url(/i/contacts-icon-phone.png) no-repeat 0 2px;
    padding-left: 35px; }
}
.contacts-overlay-inner {
  position: absolute;
  left: 10px;
  top: 0;
  transform: translateY(-50%);
  width: 410px;
  box-sizing: border-box;
  padding: 20px 10px 20px 20px;
  background: $black;
  color: $white; }
#contacts {
  .ymaps-controls-lefttop {
    margin-top: 50px;
    @media only screen and (max-width: 1336px) {
      margin-left: 440px; }
  }
}
.dealers-show {
  position: relative; }
.dealers-list-container, .balloon-content {
  overflow: hidden;
  background: $white;
  padding: 10px 10px 10px 30px;
  p + p {
    margin-top: 3px; }
  + .balloon-content {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid $yellow; }
  &.hidden {
    display: none; }
  label {
    background: $black;
    color: $white;
    float: left;
    font-size: 12px;
    width: 83px;
    height: 41px;
    line-height: 41px;
    overflow: hidden;
    padding: 0;
    text-align: center; }
  .select-container {
    display: block;
    margin-left: 83px; }
  .dealers-list {
    height: 230px;
    margin-top: 10px;
    overflow: hidden;
    position: relative;
    width: 100%;
    li {
      list-style: none;
      overflow: hidden;
      margin: 0 10px 0 !important;
      padding-top: 5px;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 80%;
        height: 1px;
        background: $yellow;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%); }
      &:first-child {
        &:before {
          display: none; }
      }
    }
  }
  .dealer-logo {
    margin: 10px 10px 0 0;
    width: 68px;
    img {
      max-width: 100%; }
  }
  .dealer-info {
    white-space: normal;
    font-size: 12px;
    line-height: 1.25;
    margin: 10px 0 10px 10px;
    width: 234px;
    p {
      margin-left: 10px;
      margin-bottom: 5px; }
  }
  .dealer-logo + .dealer-info {
    width: 156px; }
  .address {
    padding-left: 15px;
    background: url(/i/icon-address.png) no-repeat; }
  .phone {
    padding-left: 15px;
    background: url(/i/icon-phone.png) no-repeat; }
  .close {
    min-width: 0; }
}
.dealers-list-container {
  height: 338px;
  position: absolute;
  top: 68px;
  left: 10px;
  width: 280px;
  z-index: 10; }

.dealer-cards-list {
  display: flex;
  flex-wrap: wrap;

  .dealer-card {
    display: none;
    box-sizing: border-box;
    width: 25%;
    border: 5px solid white;
    background: #e7e7e7;
    padding: 20px;
    font-size: 13px;
    line-height: 1.2;

    @media screen and (max-width: 960px) {
      width: 33.33333333%;
    }

    @media screen and (max-width: 760px) {
      width: 50%;
    }

    @media $small-handheld {
      width: 100%;
      border-left-width: 10px;
      border-right-width: 10px;
    }
  }

  .dealer-card--name {
    color: $yellow;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .dealer-card--status {
    font-size: 11px;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 10px;
  }

  .dealer-card--address {
    position: relative;
    padding-left: 20px;
    margin-top: 5px;

    &:before {
      display: block;
      content: url(/i/shop-address-icon.png);
      width: 13px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 3px;
    }
  }

  .dealer-card--phone {
    position: relative;
    padding-left: 20px;
    margin-top: 5px;

    &:before {
      display: block;
      content: url(/i/shop-phone-icon.png);
      width: 14px;
      height: 14px;
      position: absolute;
      left: 0;
      top: 2px;
    }
  }

  .dealer-card--hours {
    position: relative;
    padding-left: 20px;
    margin-top: 5px;

  }

  .dealer-card--email {
    position: relative;
    padding-left: 20px;
    margin-top: 5px;

    &:before {
      display: block;
      content: url(/i/shop-mail-icon.png);
      width: 12px;
      height: 12px;
      position: absolute;
      left: 2px;
      top: 2px;
    }
  }

  .dealer-card--photo {
    margin-top: 20px;
    opacity: 0;
    transition: opacity .25s ease;

    img {
      max-width: 100%;
    }
  }

  .dealer-card:hover .dealer-card--photo {
    opacity: 1;
  }
}

.yellow-body {
  background: $yellow; }
.white-body {
  background: $white; }
.grey-body {
  background: #eae8e8; }

.quarter-block {
  width: 25%; }
.dealers-city {
  cursor: pointer;
  &:hover {
    color: $yellow; }
}

.ymaps-b-balloon {
  border: 2px solid $yellow; }
.ymaps-b-balloon__sprite {
  background: $white !important; }
.ymaps-b-balloon__content {
  padding-top: 12px;
  border: none !important;
  > ymaps {
    overflow: hidden !important;
    position: relative !important;
    .mobile & {
      overflow: scroll !important; }
  }
  .balloon-content {
    border: none; }
}
.ymaps-b-balloon__tail {
  background: $white !important; }
.ymaps-b-balloon__sprite_type_tail {
  left: 0 !important;
  margin-top: 2px !important;
  width: 16px !important;
  height: 24px !important;
  background: url(/i/balloon-corner.png) no-repeat !important; }
.ymaps-b-balloon__bl {
  width: 6px !important; }
.ymaps-shadows-pane {
  display: none !important; }

#ymaps-map, .map-block {
  >:not(.contacts-overlay) {
    .address-icon {
      position: relative;
      &:before {
        display: block;
        content: url(/i/shop-address-icon.png);
        width: 13px;
        height: 16px;
        position: absolute;
        left: -20px;
        top: 3px; }
    }
    .phone-icon {
      position: relative;
      &:before {
        display: block;
        content: url(/i/shop-phone-icon.png);
        width: 14px;
        height: 14px;
        position: absolute;
        left: -20px;
        top: 2px; }
    }
    .mail-icon {
      position: relative;
      &:before {
        display: block;
        content: url(/i/shop-mail-icon.png);
        width: 12px;
        height: 12px;
        position: absolute;
        left: -18px;
        top: 2px; }
    }
  }
}

#ymaps-map {
  @media $mobile {
    height: 210px !important;
  }

  @media $portrait-tablet {
    height: 450px !important;
  }
}

.product-main-block {
  position: relative;
  @media $not-small-handheld {
    height: 1005px;
    // margin-bottom: -170px;
    background-position: 50% 0;
    background-repeat: no-repeat;
    &.optima {
      background-image: url(/i/interior-optima.jpg); }
    &.futura {
      background-image: url(/i/interior-futura.jpg); }
    &.fusion {
      background-image: url(/i/interior-fusion.jpg); }
    &.trend {
      background-image: url(/i/interior-trend.jpg); }
    &.classic {
      background-image: url(/i/interior-vclassic.jpg); }
    &.versia {
      background-image: url(/i/interior-versia.jpg); }
    &.interior-7 {
      background-image: url(/i/interior-7.png);
      background-position-y: -80px;}
    &.favorite {
      background-image: url(/i/interior-8.jpg); }
    &.estetica {
      background-image: url(/i/interior-estetica.jpg); }
    &.avanta {
      background-image: url(/i/interior-avanta.jpg);
      background-position: 50% 41px; }
    &.elegant {
      background-image: url(/i/interior-elegant.jpg);
      background-position: 50% 41px; }
    &.vxodnye_dveri {
      background-image: url(/i/interior-entrance.jpg);
      background-position: 50% 41px; }
    &.loading {
      background: url(/i/ajax/big_loader_white.gif) no-repeat 42% 50%;
      @media screen and (max-width: 1600px) {
        background-position: 40% 50%; }
      @media screen and (max-width: 1366px) {
        background-position: 38% 50%; }
      @media screen and (max-width: 1280px) {
        background-position: 37% 50%; }
    }
    &.section_septa {
      .product-image {
        width: 1028px;
        margin-left: -734px;
        img {
          box-shadow: none; }
      }
    }
    .wrapper {
      height: 100%; }
  }
  .product-image {
    position: absolute;
    bottom: 299px;
    left: 50%;
    width: 338px;
    height: 640px;
    line-height: 640px;
    margin-left: -344px;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: bottom;
      box-shadow: 1px -4px 7px 1px rgba($black, .3), inset 0 10px 10px rgba($black, .35); }
  }
  &.vxodnye_dveri {
    .product-image {
      width: 640px;
      margin-left: -540px;
      @media screen and (max-width: 1100px) {
        width: 500px;
        margin-left: -460px;
      }
    }
    .interior-block {
      display: none;
    }
    .products-carousel-block .h1,
    .product-colors-block .h1 {
      font-size: 25px;
    }
  }
  .product-features {
    position: absolute;
    top: 75px;
    left: 10px;
    .feature {
      display: block;
      height: 49px;
      line-height: 49px;
      padding-left: 60px;
      max-width: 0;
      overflow: hidden;
      margin-top: 17px;
      &:first-child {
        margin-top: 0; }
      span {
        white-space: nowrap;
        opacity: 0;
        transition: opacity .3s ease; }
      transition: max-width .3s ease 0;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: 3px 50%;

      @media $small-handheld {
        background-size: auto 75%;
        background-position: 0 50%;
      }

      @media $desktop {
        &:hover {
          background-color: rgba($yellow, .75);
          max-width: 320px;
          span {
            opacity: 1; }
          .btn {
            opacity: 1;
            transition: opacity .3s ease; }
          &.feature-animation {
            padding-right: 90px; }
        }
      }

      span {
        display: inline-block;
        vertical-align: middle;
        line-height: 120%;
        font-size: 12px;
        color: $black;
        padding-right: 10px; }
      &.feature-animation {
        cursor: pointer;
        position: relative; }
      .btn {
        height: 39px;
        line-height: 39px;
        padding: 0 10px;
        color: $white;
        text-transform: uppercase;
        border: 1px solid $white;
        position: absolute;
        top: 4px;
        right: 5px;
        opacity: 0; }
    }
  }
  .interior-block {
    position: absolute;
    left: 50%;
    top: 90px;
    z-index: 100;
    @media only screen and (max-width: 812px) {
      left: 40%;
    }
    .interiors-link {
      cursor: pointer;
      display: block;
      width: 41px;
      height: 41px;
      background: url(/i/icon-interior.png) no-repeat 50% 50% $yellow;
      span {
        display: block;
        height: 100%;
        line-height: 41px;
        padding: 0;
        position: absolute;
        left: 100%;
        top: 0;
        background: $yellow;
        color: $white;
        font-size: 14px;
        white-space: nowrap;
        max-width: 0;
        overflow: hidden;
        transition: all .3s ease; }
      &:hover {
        span {
          padding: 0 10px;
          max-width: 200px; }
      }
    }
    .interiors-inner {
      display: none;
      position: absolute;
      top: -7px;
      left: -7px;
      padding: 54px 7px 9px 7px;
      min-height: 290px;
      background: rgba($white, .9);
      .close {
        box-sizing: border-box;
        display: block;
        width: 141px;
        height: 41px;
        line-height: 41px;
        position: absolute;
        left: 7px;
        top: 7px;
        font-size: 13px;
        text-decoration: none;
        text-align: center;
        color: $black;
        padding-left: 41px;
        &:before {
          content: '';
          display: block;
          width: 41px;
          height: 41px;
          background: url(/i/icon-back.png) no-repeat 50% 50% #000;
          position: absolute;
          left: 0;
          top: 0; }
      }
      .interior-type {
        &:first-child {
          margin-top: 0; }
        display: block;
        box-sizing: border-box;
        width: 141px;
        height: 41px;
        line-height: 25px;
        padding: 0 18px;
        background: $black;
        color: $white;
        font-family: Arial;
        font-size: 13px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        margin-top: 6px;
        border: 8px solid $black;
        transition: border-color .3s ease;
        &.active {
          border-color: $yellow; }
      }
    }
  }
  @media $not-small-handheld {
    .product-right-block {
      position: absolute;
      right: 10px;
      top: 67px;
    }
  }
  .products-carousel-block, .product-colors-block {
    @media $not-small-handheld {
      width: 375px;
      background: rgba($white, .8);
      padding: 10px 15px;
    }
    .h1 {
      font-size: 25px;
      @media $small-handheld {
        font-size: 24px;
      }
    }
    .color-name, .glass-color-name {
      display: inline-block;
      vertical-align: top;
      @media $not-small-handheld {
        height: 28px;
        width: 230px;
      }
    }
    .has-pdf-price {
      .btn {
        display: block;
        float: left;
        padding: 0 18px;
        @media $small-handheld {
          font-size: 16px;
        }
      }
      .download-price {
        position: relative;
        float: left;
        display: block;
        width: 115px;
        margin: 0 0 0 18px;
        padding: 0 0 0 40px;
        font-size: 12px;
        line-height: 41px;
        font-family: $fonts;
        text-align: left;
        text-decoration: none;
        color: #575756;
        &:after {
          content: '';
          position: absolute;
          top: 2px;
          left: 0;
          width: 33px;
          height: 38px;
          background: url(/i/pdf-icon.svg);
          background-size: cover;
        }
        &:hover {
          text-decoration: underline;
        }
        @media $small-handheld {
          max-width: 80px;
          line-height: 1.2;
          padding-top: 7px;
        }
      }
    }
  }
  @media $small-handheld {
    .products-carousel-block {
      margin-bottom: 15px;
    }
  }
  .product-share-block {
    height: 42px;
    line-height: 42px;
    padding: 0 15px;
    background: $black;
    color: $white;
    font-size: 18px;
    text-transform: uppercase; }
}

.price-details {
  padding: 5px 0;
  li {
    list-style: none;
    padding-left: 10px;
    position: relative;
    margin-bottom: 3px;
    &:before {
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      background: $yellow;
      position: absolute;
      left: 0;
      top: .45em;
    }
  }
}

.collection-block {
  background-position: 50% 0;
  background-repeat: no-repeat;
  border-bottom: 2px solid $yellow;
  padding-top: 50px;
  box-sizing: border-box;
  @media $not-small-handheld {
    min-height: calc(100vh - 121px);
  }
  @media $mobile {
    padding-top: 30px;
  }
  &:first-child {
    margin-top: 40px; }
  .screen {
    display: table;
    width: 100%;
    .screen-inner {
      display: table-cell;
      vertical-align: middle; }
  }
  .collection-logo {
    margin: 0 0 40px;
    text-align: left;
    letter-spacing: 5px;
    position: relative;
    @media $not-mobile {
      display: block;
    }
    img {
      max-height: 85px;
      @media $handheld {
        max-height: 60px;
      }
      @media $small-handheld {
        max-height: 50px;
      }
    }
  }

  a.collection-more-link {
    letter-spacing: 0;

    @media $not-mobile {
      position: absolute;
      right: 0; bottom: 0;
    }

    @media $small-handheld {
      width: auto;
      padding-right: 20px;
    }

    @media $mobile {
      font-size: 17px;
      margin-bottom: 20px;
    }
  }

  .description {
    border-left: none;
    padding-left: 0;
    line-height: 1.5;
    margin-bottom: 55px;
  }

  @media $mobile {
    .all-variants-link {
      font-size: 15px;
      line-height: 30px;
      height: 30px;
    }
  }
}

.doors-short-list {
  max-width: 980px;
  margin: 0 auto 40px;
  padding: 25px 65px;
  background: $white;
  box-shadow: 0 0 35px 14px rgba($black, .16);

  @media $mobile {
    padding: 10px;
    margin-bottom: 20px;
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    li {
      list-style: none;
      flex: 1;
    }

    li + li {
      margin-left: 10px;

      @media $mobile {
        margin-left: 5px;
      }
    }
  }

  img {
    max-width: 100%;
  }
}

.screen-block {
  box-sizing: border-box;
  position: relative;
  display: table;
  width: 100%;
  border-bottom: 2px solid $yellow;
  .screen-inner {
    display: table-cell;
    vertical-align: middle; }
}
.about-screen-block-1 {
  background: url(/i/about-bg.jpg) no-repeat 50%;
  background-size: cover;
  margin-top: 21px;
  // padding-top: 50px;
  // padding-bottom: 10px;
  .h1 + .about-text-block {
    @media only screen and (max-width: 1500px) {
      font-size: 30px; }
  }
  .h1 {
    @media only screen and (max-width: 1500px) {
      margin-bottom: 30px; }
    @media only screen and (max-width: 1190px) {
      margin-bottom: 10px; }
  }
  .fr .about-text-block {
    @media only screen and (max-width: 1500px) {
      font-size: 18px;
      .m50 {
        margin-bottom: 30px; }
    }
  }
  @media $small-handheld {
    margin-top: -17px;
    height: auto !important;
    background-size: 180% auto;
    background-position: 15% 0;
    background-color: #50575b;
    padding-top: 107%;
    padding-bottom: 30px;
    .fr {
      float: none;
      margin: 0 !important;
    }
    .h1 {
      font-size: 30px !important;
      line-height: 1.2 !important;
      margin-bottom: 20px;
    }
    .about-text-block {
      margin-left: 0 !important;
    }
  }
}
@media $not-mobile {
  .about-text-block {
    padding-left: 15px;
    border-left: 2px solid $yellow;
  }
}
.scroll-down-link {
  display: block;
  width: 41px;
  height: 41px;
  background: url(/i/scrolldown-arrow.png) no-repeat;
  position: absolute;
  left: 50%;
  bottom: 5%;
  margin-left: -20px; }
.about-screen-block-2 {
  background: url(/i/about-bg-2.jpg) no-repeat 50% 50%;
  background-size: cover;
  .about-text-block {
    width: 30%;
    font-size: 30px;
    line-height: 120%;
    margin: 0 auto;
    position: relative;
    margin-top: 75px;
    p {
      margin-top: 50px;
      &:first-child {
        margin-top: 0; }
    }
    @media only screen and (max-width: 1500px) {
      font-size: 24px;
      p {
        margin-top: 40px;
        &:first-child {
          margin-top: 0; }
      }
    }
    @media only screen and (max-width: 1190px) {
      font-size: 20px;
      p {
        margin-top: 30px;
        &:first-child {
          margin-top: 0; }
      }
    }
    @media only screen and (max-width: 1000px) {
      font-size: 17px;
      p {
        margin-top: 20px;
        &:first-child {
          margin-top: 0; }
      }
    }
    .logo-block {
      display: block;
      width: 205px;
      height: 60px;
      background: url(/i/about-status-logo.png) no-repeat;
      position: absolute;
      top: -75px;
      left: 0; }
  }
  @media $small-handheld {
    height: auto !important;
    background: none;
    .about-text-block {
      width: calc(100% - 20px);
      border-left: none;
      padding: 0;
      margin: 25px auto;
    }
    .about-table {
      tr {
        &:last-child {
          display: none;
        }
        td:first-child, td:last-child {
          display: none;
        }
        &:first-child td {
          height: 200px;
          background-size: auto 100%;
          background-position: 50%;
        }
      }
      td {
        width: 100%;
        border: none;
      }
      .text {
        padding: 20px 10px;
      }
    }
  }
}
.about-screen-block-3 {
  .scroll-down-link {
    bottom: 15px; }
  @media $small-handheld {
    padding-top: 20px;
    height: auto !important;
    .fr, .fl {
      float: none;
      width: 100% !important;
      height: auto !important;
      margin: 0 !important;
    }
    .about-text-block {
      font-size: 14px;
      line-height: 1.2 !important;
      border: none;
      padding: 0 !important;
      margin: 0 !important;
    }
    img {
      max-width: 100%;
    }
    .s36 {
      font-size: 20px;
    }
    table .about-text-block {
      padding: 100% 10px 20px !important;
      color: $white;
    }
    .nofloat {
      display: flex;
      flex-direction: column;
      > *:first-child {
        order: 2;
        margin-top: 20px !important;
        + * {
          order: 1;
        }
      }
    }
    .fr.white.s20 > div:first-child {
      // так делают только пидоры, но в данном случае западло лезть на боевом в контент
      // да и костылей хватает без того
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }
  }
}
.about-table {
  width: 100%;
  height: 100%;
  background: $white;
  tr {
    height: 33%;
    td {
      width: 33%;
      border: 10px solid $white;
      &:first-child {
        border-left: none; }
      &:last-child {
        border-right: none; }
    }
    &:first-child {
      td {
        border-top: none; }
    }
    &:last-child {
      td {
        border-bottom: none; }
    }
  }
  .pic-1 {
    background: url(/i/about-pic-1.jpg) no-repeat 50% 50%; }
  .pic-2 {
    background: url(/i/about-pic-2.jpg) no-repeat 50% 50%; }
  .pic-3 {
    background: url(/i/about-pic-3.jpg) no-repeat 50% 50%; }
  .pic-4 {
    background: url(/i/about-pic-4.jpg) no-repeat 50% 50%; }
  .pic-5 {
    background: url(/i/about-pic-5.jpg) no-repeat 50% 50%; }
  .pic-6 {
    background: url(/i/about-pic-6.jpg) no-repeat 50% 50%; }
  .pic-7 {
    background: url(/i/about-pic-7.jpg) no-repeat 50% 50%; }
  .pic-8 {
    background: url(/i/about-pic-8.jpg) no-repeat 50% 50%; }
  .text {
    padding: 0 30px;
    background: $yellow;
    font-size: 30px;
    line-height: 120%;
    @media only screen and (max-width: 1760px) {
      font-size: 24px; }
    @media only screen and (max-width: 1160px) {
      font-size: 18px; }
    @media only screen and (max-width: 890px) {
      font-size: 16px; }
  }
}

.features-screen-block-1,
.features-screen-block-2,
.features-screen-block-3,
.features-screen-block-4,
.features-screen-block-5,
.features-screen-block-6,
.features-screen-block-7 {
  .text-size-1 {
    font-size: 30px;
    line-height: 120%;
    @media only screen and (max-width: 1470px),
                screen and (max-height: 760px) {
      font-size: 25px; }
    @media only screen and (max-width: 1200px),
                screen and (max-height: 650px) {
      font-size: 20px; }
    @media $mobile {
      font-size: 14px;
    }
  }
  @media $small-handheld {
    height: auto !important;
    background: none !important;
    .screen-caption {
      height: auto;
      padding-bottom: 15px;
      .wrapper .h1 {
        font-size: 18px;
        line-height: 1.2;
        padding-left: 65px;
        text-align: left;
        background-size: 28px auto;
      }
    }
    .screen-top {
      position: static;
      margin-bottom: 15px;
      h1 {
        text-align: center;
      }
    }
    .r-main, .l-main {
      width: 100%;
      float: none;
      margin: 0;
    }
    .nofloat {
      background: none !important;
    }
    .about-text-block {
      .h1 {
        font-size: 18px;
      }
      .s24 {
        font-size: 13px;
      }
    }
    .about-text-block.left {
      /* float: none; */
      text-align: center;

      &:last-child {
        margin-right: 0;
      }

      img {
        max-height: 30px;
        margin-bottom: 10px;
      }

      + .about-text-block.left {
        /* margin-top: 15px; */
      }
    }
  }
}
.features-screen-block-1 {
  box-sizing: border-box;
  background: url(/i/features-bg-1.jpg) no-repeat 50% 0;
  background-size: cover;
  .screen-inner {
    padding-top: 190px; }
  .scroll-down-link {
    margin-left: -45px; }
  @media only screen and (max-width: 1024px) {
    .screen-caption {
      .h1 {
        text-align: center;
        font-size: 24px; }
    }
  }
  @media $small-handheld {
    margin-top: -17px;
    h1 {
      font-size: 24px;
    }
    .screen-top {
      top: 10px;
    }
    .screen-inner {
      padding-top: 10px;
    }
  }
}
.features-screen-block-2 {
  .screen-inner {
    padding-top: 140px; }
  .scroll-down-link {
    margin-left: -45px; }
  @media only screen and (max-width: 1024px) {
    .screen-caption {
      .h1 {
        font-size: 24px; }
    }
  }
  @media $small-handheld {
    .screen-inner {
      padding-top: 0;
    }
  }
}
.features-screen-block-3 {
  .screen-inner {
    padding-top: 140px; }
  .scroll-down-link {
    margin-left: -45px; }
  @media $small-handheld {
    padding-bottom: 20px;
    .screen-inner {
      padding-top: 0;
    }
  }
}
.features-screen-block-4 {
  background: $white;
  .screen-inner {
    padding-top: 140px; }
  .scroll-down-link {
    margin-left: -45px; }
  @media $small-handheld {
    padding-bottom: 20px;
    .screen-inner {
      padding-top: 0;
    }
  }
}
.features-screen-block-5 {
  background: url(/i/features-bg-2.jpg) no-repeat 50% 50%;
  background-size: cover;
  .screen-inner {
    padding-top: 140px; }
  .about-text-block {
    padding: 40px 30px;
    background: rgba($yellow, .75);
    border-left-color: $white; }
  .scroll-down-link {
    margin-left: 65px; }
  @media $small-handheld {
    padding-bottom: 20px;
    .screen-inner {
      padding-top: 0;
    }
    .about-text-block {
      padding: 0;
      background: none;
    }
  }
}
.features-screen-block-6 {
  .screen-inner {
    padding-top: 140px; }
  .scroll-down-link {
    margin-left: -45px; }
  @media $small-handheld {
    padding-bottom: 20px;
    .screen-inner {
      padding-top: 0;
    }
    .screen-caption {
      padding-top: 10px;
    }
    .h1 {
      padding-top: 10px;
    }
  }
}
.features-screen-block-7 {
  background: url(/i/features-bg-3.jpg) no-repeat 50% 40px;
  .screen-inner {
    padding-top: 40px; }
  .pictures-container {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    img {
      max-width: 100%; }
    @media only screen and (max-width: 1470px),
                screen and (max-height: 760px) {
      width: 500px; }
    @media only screen and (max-width: 1200px),
                screen and (max-height: 650px) {
      width: 400px; }
    @media $small-handheld {
      width: 100%;
    }
  }
  @media $small-handheld {
    .screen-inner {
      padding-top: 0;
    }
  }
}
.screen-top {
  position: absolute;
  left: 0;
  top: 39px;
  width: 100%;
  h1 {
    line-height: 56px;
    margin-bottom: 0; }
}
.screen-caption {
  background: rgba(#000, .75);
  height: 84px;
  .h1 {
    margin-bottom: 0;
    line-height: 84px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    padding-left: 110px;
    font-size: 34px;

    .features-screen-block-1 & {
      background-image: url(/i/feature-caption-icon-1.png); }
    .features-screen-block-2 & {
      background-image: url(/i/feature-caption-icon-2.png); }
    .features-screen-block-3 & {
      background-image: url(/i/feature-caption-icon-3.png); }
    .features-screen-block-4 & {
      background-image: url(/i/feature-caption-icon-4.png); }
    .features-screen-block-5 & {
      background-image: url(/i/feature-caption-icon-5.png); }
    .features-screen-block-6 & {
      background-image: url(/i/feature-caption-icon-6.png); }
    @media $mobile {
      font-size: 24px;
    }
  }
}

.features-hand {
  position: absolute;
  @media $handheld {
    display: none;
  }
  &.hand-1 {
    width: 419px;
    height: 415px;
    background: url(/i/hand-1.jpg) no-repeat;
    bottom: 20px;
    right: 20px; }
  &.hand-2 {
    width: 309px;
    height: 254px;
    background: url(/i/hand-2.jpg) no-repeat;
    bottom: 2px;
    left: 50%;
    margin-left: 50px; }
  &.hand-3 {
    width: 406px;
    height: 487px;
    background: url(/i/hand-3.jpg) no-repeat;
    bottom: 50px;
    left: 20px; }
  &.hand-4 {
    width: 376px;
    height: 413px;
    background: url(/i/hand-4.jpg) no-repeat;
    top: 0;
    right: 60px; }
  &.hand-5 {
    width: 417px;
    height: 318px;
    background: url(/i/hand-5.jpg) no-repeat;
    top: 0;
    left: 50%; }
  &.hand-6 {
    width: 432px;
    height: 382px;
    background: url(/i/hand-6.jpg) no-repeat;
    top: 30px;
    left: 50%;
    margin-left: -500px; }
  &.hand-7 {
    width: 305px;
    height: 400px;
    background: url(/i/hand-7.jpg) no-repeat;
    top: 50%;
    left: 50%;
    margin-left: -300px; }
  &.hand-8 {
    width: 350px;
    height: 407px;
    background: url(/i/hand-8.jpg) no-repeat;
    bottom: 2px;
    left: 50%;
    margin-left: -550px; }
  @media only screen and (max-height: 820px) {
    &.hand-4, &.hand-8 {
      display: none; }
  }
  @media only screen and (max-height: 650px) {
    &.hand-3, &.hand-5 {
      display: none; }
  }
}

.materials-screen-block-1,
.materials-screen-block-2,
.materials-screen-block-3,
.materials-screen-block-4,
.materials-screen-block-5,
.materials-screen-block-6 {
  .text-size-1 {
    font-size: 30px;
    line-height: 120%;
    @media only screen and (max-width: 1470px),
                screen and (max-height: 760px) {
      font-size: 25px; }
    @media only screen and (max-width: 1200px),
                screen and (max-height: 650px) {
      font-size: 20px; }
    @media $mobile {
      font-size: 14px;
    }
  }
  .screen-caption {
    .h1 {
      padding-left: 0; }
  }
  @media $small-handheld {
    height: auto !important;
    .screen-inner {
      padding-top: 0 !important;
      padding-bottom: 20px;
    }
    .screen-top {
      position: static;
      margin-bottom: 20px;
    }
    .about-text-block {
      font-size: 14px !important;
    }
  }
}
.materials-screen-block-1 {
  box-sizing: border-box;
  padding-top: 50px;
  @media $small-handheld {
    margin-top: -20px;
  }
  h1 {
    margin-bottom: 40px; }
  background: url(/i/materials-bg-1.jpg) no-repeat 50% 0;
  @media $small-mobile {
    background-position: 46% 0;
  }
  background-size: auto 100%;
  .about-text-block {
    margin-bottom: 35%; }
}
.materials-screen-block-2 {
  background: url(/i/materials-bg-2.jpg) no-repeat 50% 50%;
  background-size: cover;
  .screen-inner {
    padding-top: 140px; }
  .scroll-down-link {
    margin-left: -45px; }
}
.materials-screen-block-2-extra {
  .screen-inner {
    padding-top: 30px; }
  .scroll-down-link {
    margin-left: 500px; }
}
.materials-screen-block-3 {
  background: url(/i/materials-bg-3.jpg) no-repeat 50% 100%;
  .screen-inner {
    padding-top: 140px; }
  .scroll-down-link {
    margin-left: -45px;
    margin-bottom: -30px; }
  .text-size-1 {
    font-size: 19px; }
}
.materials-screen-block-4 {
  background: url(/i/materials-bg-4.jpg) no-repeat 50% 50%;
  background-size: cover;
  .screen-inner {
    padding-top: 140px; }
  .scroll-down-link {
    margin-left: -45px;
    margin-bottom: -30px; }
  .text-size-1 {
    font-size: 20px;
    @media only screen and (max-width: 1470px),
                screen and (max-height: 760px) {
      font-size: 15px; }
  }
}
.materials-screen-block-5 {
  background: url(/i/materials-bg-5.jpg) no-repeat 50% 50%;
  background-size: cover;
  .screen-inner {
    padding-top: 140px; }
  .scroll-down-link {
    margin-left: -45px;
    margin-bottom: -30px; }
  .text-size-1 {
    font-size: 20px;
    @media only screen and (max-width: 1470px),
                screen and (max-height: 760px) {
      font-size: 15px; }
  }
  .screen-caption {
    .r-main {
      float: left;
      border-left: 1px solid $white;
      width: auto;
      padding-left: 2%; }
    @media only screen and (max-width: 1470px),
                screen and (max-height: 760px) {
      .l-main {
        .h1 {
          font-size: 25px; }
      }
    }
    .l-main .h1 {
      @media $portrait-tablet {
        font-size: 21px;
      }
      @media $mobile {
        font-size: 15px;
      }
      @media $small-mobile {
        font-size: 13px;
        line-height: 1.2;
        padding-top: 20px;
      }
    }
  }
  img {
    @media only screen and (max-width: 1470px),
                screen and (max-height: 760px) {
      height: 300px; }
    @media only screen and (max-width: 1200px),
                screen and (max-height: 650px) {
      height: 200px; }
    @media $small-handheld {
      max-width: 100%;
      height: auto;
    }
  }
}
.materials-screen-block-6 {
  background: url(/i/materials-bg-6.jpg) no-repeat 50% 50%;
  .screen-inner {
    padding-top: 140px; }
}

.diploma-table {
  overflow: hidden;
  tr {
    td {
      vertical-align: top;
      padding: 8px 5px;
      text-align: center;
      &:first-child {
        padding-left: 0;
        text-align: left; }
      &:last-child {
        padding-right: 0;
        text-align: right; }
    }
  }
  a {
    display: inline-block;
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 115px;
      height: 115px;
      background: url(/i/plus.png) no-repeat 50% 50% rgba($yellow, .75);
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -58px;
      margin-top: -58px;
      opacity: 0;
      transition: opacity .3s ease; }
    &:hover {
      &:after {
        opacity: 1; }
    }
  }
  img {
    max-width: calc(100% - 6px);
    border: 3px solid $yellow; }
}

.collection-more-link {
  height: 41px;
  line-height: 41px;
  padding: 0 40px 0 55px;
  background: $yellow;
  color: $white;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 24px;
  font-family: Arial;
  font-weight: bold;
  position: relative;
  transition: background-color .3s ease;
  &:hover {
    background: lighten($yellow, 10);
    color: $white; }
  &:before {
    display: block;
    content: '';
    width: 44px;
    height: 41px;
    position: absolute;
    left: 0;
    top: 0;
    background: url(/i/icon-info.png) no-repeat 50% 50% $black; }
}

.collection-logo {
  white-space: nowrap;
  margin-left: -500px;
  margin-right: -500px;
  text-align: center;
  @media $tablet {
    margin-left: auto !important;
    margin-right: auto !important;
    img {
      max-width: 100%;
    }
  }
}

.description {
  font-size: 18px;
  line-height: 200%;
  @media $not-small-handheld {
    border-left: 2px solid $yellow;
    padding-left: 20px;
  }
  .collection-block & {
    @media only screen and (max-height: 700px) {
      font-size: 15px;
      line-height: 150%; }
  }
  @media $small-handheld {
    font-size: 14px;
    line-height: 1.35;
    .s24 {
      font-size: 15px;
    }
  }
  img {
    max-width: 100%;
  }
}

// .specs-column {
//   float: right;
//   width: 405px;
//   margin-left: 15px; }
.specs-caption {
  @media $small-handheld {
    border-top: 4px solid $yellow;
    padding-top: .75em;
    text-transform: uppercase !important;
  }
  @media $small-mobile {
    font-size: 18px !important;
  }
}
.specs-column {
  display: flex;
  line-height: 1.75;
  @media $small-handheld {
    flex-direction: column;
  }
  @media $small-mobile {
    font-size: 13px;
  }
  img {
    max-width: 100%;
  }
}
.specs-text-block {
  img {
    max-width: 100%;
  }
}
.specs-block {
  flex: 1;
  border-left: 2px solid $yellow;
  padding: 0 15px;
  margin-bottom: 45px;
  padding-top: 10px;
  padding-bottom: 20px;
  table {
    width: auto;
    img {
      margin-bottom: 10px;
    }
  }
  td {
    width: 20%;
    padding-right: 2vw;
    padding-bottom: 1em;
  }
  tr + tr td {
    vertical-align: top;
  }
  @media $small-handheld {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1.5em;
    border-left: none;
    .s36.uppercase {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .table-container {
      overflow-x: auto;
      margin-left: -10px;
      margin-right: -10px;
      padding-left: 10px;
      padding-right: 10px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  @media $small-mobile {
    td {
      width: 45vw;
    }
    td {
      padding-right: 20px;
    }
  }
}
.sizes-block {
  flex: 1;
  padding: 0 15px;
  border-left: 2px solid $yellow;
  position: relative;
  margin-top: 45px;
  padding-top: 10px;
  padding-bottom: 20px;
  line-height: 1.5;
  strong {
    font-weight: normal;
  }
  @media $small-handheld {
    padding-left: 0;
    padding-right: 0;
    border-left: none;
    border-top: 4px solid #d7d7d7;
    padding-top: 1.5em;
    line-height: 1.5;
  }
}
@media $small-handheld {
  #collection .sizes-block {
    .s17.uppercase {
      font-size: 20px;
      text-transform: uppercase;
    }
  }
}

.subcaption {
  font-size: 29px;
  line-height: 1.2;
  margin-bottom: .25em;
  @media $notebook {
    font-size: 24px;
  }
  @media $handheld {
    font-size: 24px;
  }
  @media $small-mobile {
    font-size: 18px;
  }
}

.doors-list {
  text-align: justify;
  line-height: 0;
  font-size: 0;
  &:after {
    content: '';
    display: inline-block;
    width: 100%; }
  img {
    max-width: 16%; }
  .collection-block & {
    padding: 20px 40px 60px;
    margin: 0 -40px;
    background: $white;
    border: 3px solid $yellow; }
}

.doors-screen {
  background: url(/i/grey-bg.jpg); }

.plinth-section {
  @media $not-small-handheld {
    height: 1087px;
    background: url(/i/plinth-bg.png?v=2) no-repeat 50% 0;
    background-size: contain;
    background-position-y: 50px;
  }
  @media $small-handheld {
    border-top: 2px solid $yellow;
    padding-top: 20px;
    img {
      margin-top: 10px;
      max-width: 100%;
    }
  }
  .plinths-list {
    position: absolute;
    top: 0;
    left: 645px;

    .plinth {
      position: absolute;
      width: 30px;
      height: 56px;
      background: url(/i/question-icon.png) no-repeat;
      display: none; // refs #80203

      .plinth-name {
        display: block;
        height: 32px;
        line-height: 32px;
        background: $yellow;
        text-align: center;
        color: $black;
        font-size: 20px;
        position: absolute;
        right: 0; top: 0;
        width: 190px;
        white-space: nowrap;
        overflow: hidden;
        transition: all .3s ease;
      }
      &.reversed {
        .plinth-name {
          right: auto;
          left: 0;
        }
      }
      &:hover {
        .plinth-name {
          opacity: 1;
          width: 190px;
        }
      }
    }

    .plinth-1 {
      left: 2px;
      top: 12px;
    }
    .plinth-2 {
      left: 435px;
      top: 108px;
    }
    .plinth-3 {
      left: 2px;
      top: 139px;
    }
    .plinth-4 {
      left: 435px;
      top: 204px;
    }
    .plinth-5 {
      left: 2px;
      top: 268px;
    }
    .plinth-6 {
      left: 435px;
      top: 302px;
    }
    .plinth-7 {
      left: 2px;
      top: 402px;
    }
    .plinth-8 {
      left: 435px;
      top: 394px;
    }
    .plinth-9 {
      left: 2px;
      top: 533px;
    }
    .plinth-10 {
      left: 435px;
      top: 490px;
    }
    .plinth-11 {
      left: 2px;
      top: 662px;
    }
    .plinth-12 {
      left: 435px;
      top: 588px;
    }
    .plinth-13 {
      left: 2px;
      top: 788px;
    }
    .plinth-14 {
      left: 435px;
      top: 680px;
    }
    .plinth-15 {
      left: 2px;
      top: 910px;
    }
  }
  @media $not-small-handheld {
    @media only screen and (max-width: 1280px) {
      height: 660px;
      background-size: cover;

      .plinths-list {
        left: 48%;
        top: 34px;

        @media only screen and (max-width: 812px) {
          left: 45%;
        }

        .plinth {

          .plinth-name {
            display: block;
            height: 32px;
            line-height: 32px;
            width: 160px;
            font-size: 16px;

            &:hover {
              .plinth-name {
                opacity: 1;
                width: 170px;
                font-size: 18px;
              }
            }
          }
        }

        .plinth-1 {
          left: 64px;
          top: -28px;
        }
        .plinth-2 {
          left: 278px;
          top: 7px;
        }
        .plinth-3 {
          left: 64px;
          top: 45px;
        }
        .plinth-4 {
          left: 278px;
          top: 72px;
        }
        .plinth-5 {
          left: 64px;
          top: 120px;
        }
        .plinth-6 {
          left: 278px;
          top: 135px;
        }
        .plinth-7 {
          left: 64px;
          top: 200px;
        }
        .plinth-8 {
          left: 278px;
          top: 200px;
        }
        .plinth-9 {
          left: 64px;
          top: 274px;
        }
        .plinth-10 {
          left: 278px;
          top: 260px;
        }
        .plinth-11 {
          left: 64px;
          top: 344px;
        }
        .plinth-12 {
          left: 278px;
          top: 325px;
        }
        .plinth-13 {
          left: 64px;
          top: 424px;
        }
        .plinth-14 {
          left: 278px;
          top: 390px;
        }
        .plinth-15 {
          left: 64px;
          top: 500px;
        }
      }
    }
  }
}

.post-counter {
  width: 30px;
  height: 56px;
  line-height: 32px;
  color: $white;
  font-size: 16px;
  text-align: center;
  background: url(/i/post-counter.png) no-repeat; }
.answer-section {
  padding: 20px 0 10px;
  .left {
    margin-right: 30px; }
}

.pager {
  ul {
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: right;
    font-size: 0;
    @media $mobile {
      text-align: center;
    }
    li {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      padding: 0;
      list-style: none;
      width: 30px;
      height: 29px;
      line-height: 29px;
      text-align: center;
      width: 41px;
      height: 41px;
      margin-left: -2px;
      a {
        box-sizing: border-box;
        display: block;
        height: 100%;
        color: $black;
        text-decoration: none;
        font-size: 16px;
        line-height: 37px;
        border: 2px solid $black;
        &:hover {
          color: $yellow; }
      }
      &.page {
        &.selected {
          position: relative;
          background: url(/i/pager-active.png) no-repeat 50% 50%;
          a {
            color: $black;
            border-top: none;
            border-bottom: none;
            background: #eae8e8; }
        }
      }
      &.previous, &.next {
        a {
          display: block;
          width: 100%;
          height: 100%;
          position: relative;
          &:after {
            content: '';
            display: block;
            width: 15px;
            height: 21px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -8px;
            margin-top: -10px;
            background: url(/i/pager-arrows.png) no-repeat; }
          &:hover {
            border-color: $yellow; }
        }
      }
      &.previous {
        a {
          &:hover {
            &:after {
              background-position: 0 100%; }
          }
        }
      }
      &.next {
        a {
          &:after {
            margin-left: -7px;
            background-position: 100% 0;
          }
          &:hover {
            &:after {
              background-position: 100% 100%; }
          }
        }
      }
    }
  }
}

.pager-style-2 {
  ul {
    li + li {
      margin-left: 15px;
    }

    li a {
      border: 2px solid transparent;
      background: #a1a1a1;
      color: $white;
    }

    li.selected a {
      border: 2px solid $yellow !important;
      color: #a1a1a1;
    }
  }
}

.footer-registration {
  overflow: hidden;
  background: $black;
  font-size: 21px;
  @media only screen and (max-width: 1320px) {
    font-size: 18px; }
  @media only screen and (max-width: 1150px) {
    font-size: 15px; }
  @media only screen and (max-width: 1024px) {
    font-size: 12px; }
  .wrapper {
    height: 60px;
    line-height: 64px; }
  form {
    margin-top: 10px;
    line-height: 0; }
  .inp {
    width: 328px;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 18px;
    color: #6f6f6f;
    background: #fbb034; }
  .subscribe-btn {
    display: inline-block;
    border: none;
    width: 38px;
    height: 40px;
    background: url(/i/subscribe-btn.png) no-repeat;
    font-size: 0;
    margin-left: 8px; }
}
footer {
  .counters {
    display: none;
    float: right;
    padding-top: 56px;
    img {
      float: left;
      margin-right: 10px; }
  }
  .copyright {
    float: left;
    font-size: 12px;
    line-height: 180%;
    color: $white;
    img {
      /* width: 100px; */
      margin: -7px 10px 0 0; }
  }
  @media $small-handheld {
    .inner, .copyright {
      background: rgba(#000, .48);
    }
    .inner {
      margin-bottom: 0;
      padding: 18px 10px 18px;

      > div {
        line-height: 1.75;
      }
    }
    .copyright {
      float: none;
      padding-bottom: 25px;
      a {
        display: block;
        margin-bottom: 5px;
      }
    }
  }
}

.footer-inner {
  @media $not-small-handheld {
    .footer-column {
      // height: 200px;
      padding-top: 15px; }
    .first-column {
      float: left;
      width: 210px; }
    .second-column {
      margin-left: 260px;
      margin-right: 420px; }
  }
  @media $small-handheld {
    .first-column {
      padding: 13px 19px 0;
      text-align: center;
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .second-column {
      display: none;
    }
  }
  .third-column {
    @media $not-small-handheld {
      width: 370px;
      height: $footer-height;
      float: right;
      padding-top: 0;
      box-sizing: border-box;
      background: rgba(#000, .75);
      padding: 22px 15px 5px 20px;
      /* @media only screen and (max-width: 1130px) {
        position: absolute;
        top: $footer-height;
        left: 0;
        width: 100%; } */
      @media only screen and (max-width: 1130px) {
        height: 260px;
        margin-right: -10px;
      }
    }
    @media $small-handheld {
      text-align: center;
    }
  }
  .phone-number {
    display: inline-block;
    margin-top: 30px;
    font-family: Arial;
    font-size: 27px;
    font-weight: bold;
    @media $small-handheld {
      margin-top: 18px;
    }
  }
  .bottom-menu {
    .download-price-footer {
      margin: 0;
      font-weight: normal;
      text-transform: none;
      color: #fff;
      white-space: nowrap;
      &:hover {
        color: #b3b3b3;
      }
    }
  }
}
.icon-user, .icon-letter {
  display: inline-block;
  width: 37px;
  height: 37px;
  border: 2px solid #eaeaea;
  &:hover {
    border-color: $yellow;
    background-position: 50% -43px; }
}
.icon-user {
  background: url(/i/icon-user.png) no-repeat 50% -2px; }
.icon-letter {
  /* background: url(/i/icon-letter.png) no-repeat 50% -2px;  */
  border-color: $yellow;
  background: url(/i/icon-letter.png) no-repeat 50% -43px;
  &:hover {
    opacity: .85;
  }
}
.footer-socials {
  font-size: 0;
  @media $small-handheld {
    margin-top: 15px;
    margin-bottom: 18px;
  }
  a {
    display: inline-block;
    width: 43px;
    height: 42px;
    background-color: $yellow;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-left: 13px;
    transition: all .3s ease;
    &:first-child {
      margin-left: 0; }
    &:hover {
      background-color: $black; }
    @media $small-handheld {
      width: 50px; height: 49px;
      margin-left: 16px;
    }
  }
  .sm-fb {
    background-image: url(/i/sm-fb.png); }
  .sm-twit {
    background-image: url(/i/sm-twit.png); }
  .sm-vk {
    background-image: url(/i/sm-vk.png); }
  .sm-utb {
    background-image: url(/i/sm-utb.svg); background-size: 28px; }
  .sm-ok {
    background-image: url(/i/sm-ok.png); }
  .sm-inst {
    background-image: url(/i/sm-inst.png); }
  .sm-tg {
    background-image: url(/i/sm-tg.svg); background-position: 45% 50%; }
}

.page-404 {
  background: url(/i/404-bg.jpg) no-repeat 50% 50%;
  background-size: cover; }
.page-404-body {
  display: table;
  width: 100%;
  height: 100%; }
.page-404-inner {
  display: table-cell;
  vertical-align: middle;
  .wrapper {
    padding: 15px 0 15px 55%; }
  .error-text {
    font-size: 25px;
    line-height: 120%; }
  img {
    @media only screen and (max-width: 1024px) {
      width: 80%; }
  }
}

.go-top-btn {
  display: block;
  width: 38px;
  height: 38px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  background: url(/i/small-arrow-up.png) no-repeat 50% 50% rgba($white, .5);
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s ease;
  &.visible {
    visibility: visible;
    opacity: .85; }
  &:hover {
    opacity: 1; }
  @media $small-handheld {
    display: none;
  }
}

/*   -=========== Menu classes ===========-   */
.menu {
  ul {
    li {
      list-style: none;
      a {
        text-decoration: none; }
    }
  }
}
.horizontal-menu {
  > ul {
    display: table;
    font-size: 0;
    > li {
      display: inline-block;
      font-size: $text-size; }
  }
}
.top-menu {
  //box-sizing: border-box;
  float: left;
  max-width: 648px;
  width: 100%;
  @media only screen and (max-width: 1265px) {
    width: 48%;
  }
  @media only screen and (max-width: 1024px) {
    //padding: 0 230px 0 225px;
    width: 45%;
  }
  @media only screen and (max-width: 880px) {
    width: 43%;
  }
  @media only screen and (max-width: 840px) {
    width: 38%;
  }
  @media only screen and (max-width: 812px) {
    display: none;
  }
  @media only screen and (max-width: 530px) {
    ul {
      li {
        margin-top: 0; }
    }
  }
  ul {
    width: 100%;
    @media only screen and (max-width: 900px) {
      padding-top: 20px;
      li {
        display: inline-block;
        float: left;
        margin-right: 10px;
        a {
          height: 20px;
          line-height: 20px;
        }
      }
    }
    li {
      display: table-cell;
      vertical-align: middle;
      &.active {
        a {
          color: $yellow; }
      }
      &:first-child {
        a {
          background: $yellow;
          &:hover {
            background: lighten($yellow, 7); }
          transition: background-color .15s ease;
          padding: 0 10px;
          height: 40px;
          line-height: 40px;
          color: $white !important;
          font-family: Arial;
          font-size: 25px;
          font-weight: bold;
          @media only screen and (max-width: 1200px) {
            font-size: 22px; }
          @media only screen and (max-width: 1170px) {
            font-size: 18px; }
        }
      }
    }
  }
  a {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    color: $black;
    &:hover {
      color: $yellow; }
    @media only screen and (max-width: 1300px) {
      font-size: 15px; }
    @media only screen and (max-width: 1200px) {
      font-size: 14px; }
    @media only screen and (max-width: 1170px) {
      font-size: 10px; }
  }
  .has-dropdown {
    position: relative;
  }
  ul.dropdown {
    max-height: 0;
    overflow: hidden;
    padding: 0 18px;
    position: absolute;
    left: 0; top: 100%;
    z-index: 10;
    margin-top: -20px;
    background: $white;
    box-shadow: 0 0 7px rgba($black, .16);
    text-align: left;
    transition: all .25s ease;
    opacity: 0;
    li {
      display: block;
      a {
        display: block;
        background: none !important;
        color: $black !important;
        line-height: 1.2 !important;
        height: auto;
        padding: 0;
        font-size: 15px;
        font-weight: normal;
        @media $handheld {
          font-size: 13px;
          padding: 0;
        }
        &:hover {
          color: $yellow !important;
        }
      }
    }
    li + li {
      margin-top: .75em;
    }
  }
  .has-dropdown:hover,
  .has-dropdown.is-hovered {
    .dropdown {
      width: 100%;
      box-sizing: border-box;
      max-height: 540px;
      padding: 25px 18px;
      opacity: 1;
      li a {
        text-transform: uppercase;
      }
    }
  }
}

.opened--link {
  margin-bottom: 370px;

  @media only screen and (max-width: 768px) {
    height: 0;
    margin-bottom: 0;
  }
}

#dveri_skrytogo_montazha {
  min-height: 0;
}

.collections-menu {
  position: fixed;
  top: $header-height;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(#252525, .43);

  @media only screen and (max-width: 812px) {
    display: none;
  }

  ul {
    display: block;
    text-align: center;
    white-space: nowrap;

    li {
      vertical-align: top;

      &:first-child {
        + li {
          margin-left: 41px;
        }
      }

      a {
        display: block;
        height: 41px;
        line-height: 41px;
        padding: 0 10px;
        background: transparent;
        font-size: 14px;
        color: $white;
        text-transform: uppercase;
        transition: background-color .3s ease;

        @media only screen and (max-width: 1200px) {
          font-size: 11px;
          padding: 0 8px;
        }
      }

      &.active,
      &:hover {
        a {
          background: $yellow;
        }
      }

      &.home {
        position: absolute;
        left: 10px;
        top: 0;

        a {
          width: 41px;
          height: 41px;
          padding: 0;
          background: url(/i/home.png) no-repeat 50% 50% $black;
          transition: background-color .3s ease;

          &:hover {
            background-color: $yellow;
          }
        }
      }
    }
  }
}

.card-menu {
  position: fixed;
  right: 0;
  top: 150px;
  z-index: 200;
  li {
    margin-bottom: 2px;
    text-align: right;
    a {
      display: inline-block;
      height: 26px;
      line-height: 26px;
      color: $white;
      text-transform: uppercase;
      font-size: 11px;
      padding: 0 8px;
      background: rgba($black, .45);
      border-left: 2px solid $yellow;
      transition: all .35s ease;
      &:hover {
        padding-right: 16px;
        background: $black; }
    }
    &.active {
      a {
        padding-right: 16px;
        background: $yellow; }
    }
  }
  @media only screen and (max-width: 1560px) {
    display: none; }
}
.bottom-menu {
  width: 100%;
  padding-top: 5px;
  a {
    transition: color .3s ease; }
  > ul {
    display: table;
    width: 100%;
    > li:first-child {
      width: 44%; }
  }
  .column {
    display: table-cell;
    vertical-align: top;
    min-width: 135px;
    &:first-child {
      margin-left: 0; }
    > a, > span {
      display: inline-block;
      color: $yellow;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px; }
    > a {
      &:hover {
        color: lighten($yellow, 20); }
    }
    ul {
      li {
        margin-bottom: 10px;
        a {
          color: $white;
          font-size: 15px;
          &:hover {
            color: darken($white, 30); }
        }
      }
    }
  }
}

.guarantee {
  float: left;
  width: 65px;
  height: 66px;
  margin: 0 26px;
  background: url('/i/5let.png');
}

.vitrine {
  font-size: 0;

  @media $small-handheld {
    margin-right: -10px;
  }

  .product {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    background: $white;
    padding: 20px;
    text-decoration: none;
    text-align: center;

    @media $not-small-handheld {
      margin-left: 10px;
      width: calc(20% - 8px);

      &:nth-child(5n + 1) {
        margin-left: 0;
      }
    }

    @media $small-handheld {
      width: calc(33% - 8px);
      margin-right: 8px;
      margin-bottom: 10px;
    }

    @media $small-mobile {
      width: calc(50% - 8px);
    }
  }

  .product--image {
    margin-bottom: 10px;
  }

  .product--name {
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }

  .product--description {
    font-size: 12px;
  }
}

.scroll-nav {
  font-size: 0;

  li {
    list-style: none;
    display: inline-block;

    + li {
      margin-left: 20px;
    }
  }

  a {
    display: block;
    padding: 15px 30px;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: bold;
    background: $black;
    color: $yellow;
    border: 1px solid $yellow;
    text-decoration: none;

    &:hover {
      background: $yellow;
      color: $black;
    }
  }

  @media $small-mobile {
    li + li {
      margin-left: 10px;
    }

    a {
      padding: 10px 20px;
      font-size: 13px;
    }
  }
}

/*   -=========== Popup classes ===========-   */
.popup {
  display: none;
  position: absolute;
  z-index: 9999;
  .close {
    font-size: 0;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url('/i/jquery-fancybox/fancybox_sprite.png');
    width: 36px;
    height: 36px; }
}
.shpon-popup {
  width: 80%;
  .screen-inner {
    display: block; }
}
.door-animation-popup {
  background: $white;
  padding: 5px 30px 20px;
  border: 2px solid $yellow;
  @media $mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media $small-mobile {
    .btn + .btn {
      margin-left: 0;
      margin-top: 10px;
    }
  }
  .close {
    width: 42px;
    height: 41px;
    background: url(/i/close-cross.png) no-repeat 50% 50% $yellow;
    top: 5px;
    right: 5px;
    transition: background-color .3s ease;
    &:hover {
      background-color: lighten($yellow, 10); }
  }
  .popup-caption {
    height: 42px;
    line-height: 24px;
    padding: 0 45px 0 0;
    text-align: center;
    font-size: 17px;
    text-transform: uppercase;
    &:before {
      display: inline-block;
      vertical-align: middle;
      content: url(/i/icon-status.png);
      margin-right: 8px; }
  }
  .door-animation {
    background: url(/i/ajax/big_loader_white.gif) no-repeat 50% 50%; }
  .video-animation {
    width: 800px;
    padding-bottom: 56.25%;
    position: relative;
    margin-top: 20px;
    @media only screen and (max-width: 800px) {
      width: calc(100vw - 24px);
    }
    video {
      width: 100%; height: 100%;
      position: absolute;
      left: 0; top: 0;
    }
  }
}

/*   -=========== Buttons ===========-   */
.btn {
  display: inline-block;
  vertical-align: middle;
  border: none;
  text-decoration: none;
  text-align: center; }
.dark-btn {
  padding: 0 20px;
  background: rgba(#000, .75);
  color: $yellow;
  font-family: Arial;
  border: 2px solid $yellow;
  transition: all .3s ease;
  &:hover {
    background: $black;
    color: lighten($yellow, 10); }
}
.yellow-btn {
  background: $yellow;
  color: $white;
  padding: 0 34px;
  font-family: Arial;
  transition: all .3s ease;
  &:hover {
    background: lighten($yellow, 10);
    color: $white; }
}
.contour-btn {
  background: $white;
  border: 1px solid $black;
  color: $black;
  padding: 0 10px;
  .btn + & {
    margin-left: 15px; }
  &:hover, &.active {
    color: $yellow;
    border-color: $yellow; }
}
.h41btn {
  height: 41px;
  line-height: 41px; }
.h55btn {
  height: 55px;
  line-height: 55px; }
.wide-paddings-btn {
  padding-left: 36px;
  padding-right: 36px; }
.play-btn {
  position: relative;
  padding: 0 15px 0 35px;
  &:before {
    content: '';
    display: block;
    width: 15px;
    height: 22px;
    background: url(/i/icon-play.png) no-repeat;
    position: absolute;
    left: 12px;
    top: 10px; }
}

/*   -=========== Specific mobile styles ===========-   */
/* .mobile {
  body {
    min-width: $wrapper-min-width + 30px; }
} */
.phone-number {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  .mobile & {
    pointer-events: auto; }
}

.mobile-menu {
  overflow: auto;
  width: 100%;
  position: fixed;
  top: $mobile-header-height; left: 0;
  z-index: 1001;
  max-height: 0;
  transition: max-height .25s ease;

  &.is-active {
    max-height: 'calc(100vh - %s)' % $mobile-header-height;
    max-height: 'calc(var(--vh, 1vh) * 100 - %s)' % $mobile-header-height;
  }

  ul ul li a {
    display: block;
    line-height: 44px;
    padding: 0 23px;
  }

  .mobile-collections-menu {
    background: $body-background;

    > a, > span {
      display: block;
      background: $yellow;
      color: $white;
      font-size: 18px;
      text-align: center;
      line-height: 38px;
      text-transform: uppercase;
    }

    li a {
      font-size: 18px;
      text-transform: uppercase;
      color: #575756;
    }

    li + li {
      border-top: 2px solid #dfdcdc;
    }
  }

  .mobile-about-menu {
    background: #3e3e3e;

    > a, > span {
      display: block;
      background: #3e3e3e;
      line-height: 38px;
      font-size: 18px;
      text-transform: uppercase;
      color: $white;
      text-align: center;
    }

    li a {
      font-size: 15px;
      color: $white;
      background: #55575b;
    }

    li + li {
      border-top: 2px solid #3e3e3e;
    }
  }
}

@media $small-mobile {
  #planed {
    .planed-scheme {
      width: 100%; height: auto;
      // padding-bottom: 42%;
      background-size: 100% auto;
    }
    // .planed-scheme-back,
    // .planed-scheme-straight {
    //   padding-bottom: 120%;
    // }
  }
}

@media only screen and (max-width: 812px) {
  .collection-logo {
    display: none;
  }
}

@media $small-handheld {
  .variant-price-block {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;

    br {
      display: none;
    }

    .price-details {
      margin-top: 5px;
    }
  }

  #collection,
  #planed,
  .plinth-section {
    .h1,
    .s17.uppercase {
      font-size: 20px;
      text-transform: none;
      margin-bottom: 10px;
    }
  }

  #features .h1,
  #materials .h1 {
    font-size: 20px;
  }

  .collection-logo {
    display: none;
  }

  #planed {
    table:not(.sizes-table) {
      td {
        display: block;
        padding-right: 0 !important;
      }
    }
    img {
      max-width: 100%;
    }
    /* .planed-scheme {
      width: 100%; height: auto;
      padding-bottom: 42%;
      background-size: 100% auto;
    } */
    .s24,
    .s22 {
      font-size: 14px;
    }
    .schemes-table {
      td {
        text-align: center !important;
      }
      td + td {
        margin-top: 25px;
      }
    }
    .m30 {
      margin-bottom: 20px;
    }
  }

  .collection-block {
    background: none !important;
    border-bottom: none;
    &:first-child {
      margin-top: 0;
    }
    &:nth-child(even) {
      background: url(/i/grey-bg.jpg) !important;
    }
    .screen {
      height: auto !important;
    }
    .h1 {
      font-size: 24px;
    }
    .description {
      font-size: 14px;
      line-height: 1.35;
      margin-bottom: 15px;
    }
    .left.s28 {
      font-size: 14px;
      float: none;
      margin-bottom: 15px;
      span {
        font-size: 24px;
        font-weight: bold;
      }
    }
    .collection-more-link {
      float: none;
      margin-left: 0;
      width: 100%;
      box-sizing: border-box;
      font-size: 18px;
      line-height: 33px;
      height: 33px;
      padding-right: 0;
      padding-left: 45px;
      display: block;
      &:before {
        width: 34px; height: 33px;
        background-size: auto 65%;
      }
    }
    .doors-screen {
      background: none;
      .h1 {
        font-size: 20px;
      }
    }
    .doors-list {
      padding: 0;
      margin: 0;
      border: none;
      background: none;
    }
  }

  .features-caption {
    font-size: 24px;
  }

  .mobile-catalogue-breadcrumbs {
    text-align: center;
    font-size: 14px;
    line-height: 29px;
    text-transform: uppercase;
    .newListSelected {
      display: inline-block;
      margin-left: 15px;
      width: 183px;
      .selectedTxt {
        border: 1px solid #55575b;
        line-height: 27px;
        height: 27px;
        padding: 0 33px 0 10px;
        &:after {
          width: 28px; height: 100%;
          top: 0; right: 0;
          margin: 0;
          background: #55575b url(/i/select-arrow-mini.png) no-repeat 50%;
        }
      }
    }
  }

  .shops-wrapper {
    padding: 5px 0 !important;
  }

  .contact-text-wrapper {
    padding: 22px 10px 25px !important;

    td {
      display: block;
      width: 100% !important;
      font-size: 14px;

      + td {
        padding-top: 25px;
        text-align: center !important;
      }
    }

    .btn {
      font-size: 16px;
      padding: 0 20px;
      line-height: 38px;
      height: 38px;
    }
  }

  .map-control-wrapper {
    margin-top: 0 !important;
    .select-container {
      width: 100% !important;
      .selectedTxt {
        padding: 0 33px 0 15px;
        height: 29px;
        line-height: 27px;
        font-size: 14px;
        text-transform: uppercase;
        &:after {
          width: 28px; height: 100%;
          top: 0; right: 0;
          margin: 0;
          background: #55575b url(/i/select-arrow-mini.png) no-repeat 50%;
        }
      }
      .SSContainerDivWrapper {
        top: 28px !important;
      }
    }
  }

  .mobile-map-toggler {
    display: inline-block;
    margin: 10px 10px 20px;
    padding-left: 40px;
    font-size: 14px;
    text-decoration: underline;
    background: url(/i/map-icon.svg) no-repeat 0 50% / 25px auto;
    line-height: 22px;

    &.is-active {
      .collapsed-text {
        display: none;
      }
    }

    &:not(.is-active) {
      .expanded-text {
        display: none;
      }
    }
  }

  .info-menu {
    display: none;
  }

  #contacts {
    height: auto !important;

    .map-block {
      margin-top: -17px !important;
      display: flex;
      flex-direction: column;
    }

    #ymaps-map {
      order: 2;
      font-size: 0;
      line-height: 0;

      .ymaps-map {
        font-size: 12px;
        line-height: 1.2;
      }
    }

    .contacts-overlay {
      order: 1;
    }

    .contacts-overlay,
    .contacts-overlay-inner {
      position: static;
    }

    .contacts-overlay-inner {
      width: auto;
      transform: none;
      background: $white;
      padding: 30px 10px 15px;
      margin: -10px -10px 0;
      color: #575756;

      .s34 {
        font-size: 23px;
        text-align: center;
        margin-bottom: 10px;

        + .s22 {
          text-align: center;
          margin-bottom: 12px;
        }
      }

      .s22 {
        font-size: 14px;
        margin-bottom: 10px;
      }

      .hr1 {
        width: 100%;
        margin-top: -20px;
        margin-bottom: 25px;
      }

      .status-icon {
        padding-left: 0;
        background: none;
      }
    }
  }

  .become-a-dealer-page {
    margin-top: -17px;
    padding-top: 15px;
    min-height: 0;

    h1 {
      font-size: 23px;
      text-align: center;
    }

    .form {
      margin-bottom: 25px;
    }
  }

  .response-page {
    margin-top: -17px;
    padding-top: 20px;

    h1, .h1 {
      font-size: 24px;
      text-align: center;
    }
  }
}


@media screen and (min-width: 1025px) {
  .product-main-block {
    height: auto;
    min-height: 1005px;

    .product-right-block {
      position: static;
      padding-top: 67px;
    }

    .products-carousel-block {
      margin-left: auto;
      position: relative;
      z-index: 15;
    }

    .product-colors-block {
      width: calc(100% - 30px);
      background: rgba($white, .5);

      .h2 {
        margin-bottom: 15px;
      }
    }

    .product-image {
      z-index: 10;
    }

    .product-features {
      z-index: 16;
    }

    .product-image {
      bottom: auto;
      top: 71px;
    }

    .product-share-block {
      float: right;
    }
  }

  .products-carousel-container {
    min-height: 643px;
  }

  .products-carousel {
    .carousel-container {
      height: auto;
    }

    ul.carousel {
      width: 100%;
      position: static;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 15px;

      li {
        width: auto !important;
        margin-right: 0;
      }

      li a {
        width: 100%;
        height: auto;
        line-height: 1.2;
      }
    }

    .carousel-controls {
      display: none !important;
    }

    .name {
      text-align: center;
      margin-top: 3px;
      color: #000;
      font-size: 14px;
    }
  }

  .vxodnye_dveri,
  .section_septa {
    .products-carousel {
      ul.carousel {
        grid-template-columns: repeat(4, 1fr);
      }

      .name {
        font-size: 11px;
      }
    }
  }

  .avanta {
    .products-carousel {
      .name {
        font-size: 11px;
      }
    }
  }

  .variants-carousel {
    padding: 0;

    .carousel-container {
      width: 100%;
      height: auto;
    }

    ul.carousel {
      width: 100%;
      position: static;
      display: flex;
      flex-wrap: wrap;

      li {
        width: 60px;
        margin-right: 5px;
      }

      li a {
        width: 100%;
        height: auto;
        line-height: 1.2;
      }
    }

    .carousel-controls {
      display: none !important;
    }
  }

  .color-name,
  .glass-color-name {
    height: auto !important;
  }

  .option-colors-block {
    margin-top: 1em;
  }

  .variant-price-block {
    position: absolute;
    left: 80px; top: 350px;
    z-index: 15;
    width: 185px;
    text-align: center;
    background: rgba($white, .8);
    padding: 10px;
    font-size: 18px;

    .optima &,
    .versia &,
    .futura &,
    .avanta & {
      background: rgba($white, .35);
    }

    .price-details {
      text-align: left;
      font-size: 11px;
    }

    .s12 {
      font-size: 10px;

      br {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .products-carousel {
    .name {
      display: none;
    }
  }
}


/* -=========== sections adaptive page ==============- */




@media screen and (max-width: 768px){
  #update-product-block a.product-image{
    position: relative;
    display: block;
    width: 200px;
    height: auto;
    left: auto;
    bottom: auto;
    line-height: 200px;
    margin: 8px auto 24px;
    .vxodnye_dveri & {
      width: 235px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  #update-product-block a.product-category-peregorodki{
    width: 100%;
    max-width: 500px;
    min-width: 300px;
    line-height: 300px;
  }
  #update-product-block a.product-image img{
    vertical-align: middle;
    box-shadow: none;
  }
  .product-title-vertical{
    position: absolute;
    writing-mode: tb-rl;
    top: 0;
    right: 0;
    margin-right: 24px;
    text-align: left;
    .vxodnye_dveri & {
      font-size: 0 !important;
      span {
        display: block;
        font-size: 16px;
      }
      span.product-name {
        margin-right: 1.2em;
      }
    }
  }
  .product-title-vertical span.model-name { color: #ffa500; }
  .product-title-vertical span.product-name { }

  #products-carousel,
  #colors-carousel,
  #glass-carousel{
    display: none;
  }
  .products-carousel-block{
    border-top: 1px solid #cccccc;
    padding-top: 16px;
  }
  .product-colors-block{
    border-top: 1px solid #cccccc;
    padding-top: 16px;
  }
  .option-colors-block{
    border-top: 1px solid #cccccc;
    padding-top: 16px;
  }

  .spoiler-button {
    position: absolute;
    left: 10px;
    right: 10px;
    width: calc(100% - 20px);
    height: 48px;
    margin-top: -4px;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);

    &:before {
      position: absolute;
      content: '';
      width: 8px;
      height: 8px;
      margin-top: 9px;
      margin-left: 11px;
      right: 10px;
      border-bottom: 2px solid $yellow;
      border-left: 2px solid $yellow;
      ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      transition: 0.45s;
    }
  }
  .spoiler-button-opened:before {
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

}



.breadcrumbs {
  padding-top: 5px;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 300;
  margin-left: 0px;

  @media only screen and (max-width: 1023px) {
    overflow: hidden;
    height: 0;
    margin-top: 0;
    margin-bottom: 1em;
  }

  li {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    color: #aaa;

    a, span {
      margin-right: 24px;

      &:after {
        content: '|';
        display: inline-block;
        box-sizing: border-box;
        width: 10px;
        height: 10px;
        position: absolute;
        transform: translateY(0px) translateX(10px);
      }
    }
    &:last-of-type{
      a:after {
        content: none;
      }
    }
  }

  a {
    text-decoration: none;
    color: #666;
    letter-spacing: 0.33px;

    &:hover {
      color: #ffb605;
    }
  }
}

.floated-container {
  display: block;
  position: absolute;
  z-index: 100;
  width: 100%;
  margin-top: 40px;
}

.systems-page {
  background: url(/i/systems-bg.jpg) no-repeat 50% / cover;
  padding-top: 70px;
  padding-bottom: 130px;

  @media only screen and (max-width: 768px) {
    padding-top: 50px;
    margin-top: -17px;
  }

  @media only screen and (max-width: 480px) {
    padding-top: 30px;
    padding-bottom: 50px;
  }

  .h1 {
    font-size: 66px;

    @media only screen and (max-width: 1024px) {
      font-size: 50px;
    }

    @media only screen and (max-width: 480px) {
      font-size: 24px;
    }
  }
}

.systems-list {
  background: $white;
  padding: 40px 85px 20px;
  margin-left: -85px;
  margin-right: -85px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width: 1024px) {
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .item {
    text-decoration: none;
    margin-bottom: 65px;

    @media only screen and (min-width: 769px) {
      margin-left: 10%;

      @media only screen and (max-width: 1024px) {
        margin-left: 2%;
      }
    }

    @media only screen and (max-width: 480px) {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 35px;
    }
  }

  @media only screen and (min-width: 769px) {
    .item:nth-child(3n + 1) {
      margin-left: 0;
    }
  }

  .item__name {
    font-size: 26px;
    margin-bottom: .5em;

    @media only screen and (max-width: 480px) {
      font-size: 20px;
    }
  }
}

.sitemap {
  li {
    list-style: none !important;
  }

  a {
    text-decoration: none;
  }

  @media only screen and (max-width: 480px) {
    .l-main, .r-main {
      width: 100%;
      float: none;
      margin-right: 0;
    }

    .l-main {
      margin-bottom: 2em;
    }
  }
}


.surfaces-section {
  margin-bottom: 40px;
}

.surfaces-section__layout {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;

  @media only screen and (max-width: 480px) {
    display: block;
  }
}

.surfaces-section__column:nth-child(3) {
  min-width: 252px;
}

.surfaces-section__column + .surfaces-section__column {
  margin-left: 6vw;

  @media only screen and (max-width: 1460px) {
    margin-left: 8vw;
  }

  @media only screen and (max-width: 1024px) {
    margin-left: 11vw;
  }

  @media only screen and (max-width: 768px) {
    margin-left: 14vw;
  }

  @media only screen and (max-width: 480px) {
    margin-left: 0;
    margin-top: 10px;
  }
}

.surfaces-section__subcaption {
  font-size: 25px;
  margin-bottom: .5em;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.surfaces-section__list {
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 480px) {
    margin-right: -6px;
  }

  .item {
    display: block;
    text-decoration: none;
    margin-right: 6px;
    color: #575756;
    margin-bottom: 18px;
    width: 120px;

    img {
      max-width: 100%;
    }

    @media only screen and (max-width: 1024px) {
      width: 90px;
    }

    @media only screen and (max-width: 768px) {
      width: 60px;
    }

    @media only screen and (max-width: 480px) {
      width: calc(20% - 6px);
    }

    @media only screen and (max-width: 320px) {
      width: calc(25% - 6px);
    }
  }

  .item__caption {
    font-size: 16px;
    margin-top: 7px;

    @media only screen and (max-width: 1024px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.surfaces-section__text {
  font-size: 12px;
  line-height: 1.2;
  max-width: 880px;
}

.table-wrapper {
  @media only screen and (max-width: 1280px) {
    overflow-x: auto;
  }
}

.sizes-table {
  max-width: 100%;
  width: 1080px;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  font-size: 20px;

  .opened & {
    font-size: 16px;

    @media only screen and (min-width: 1281px) {
      margin-left: -60px;
    }

    @media $hanheld {
      font-size: 14px;
    }

    @media $small-mobile {
      font-size: 12px;
    }
  }

  @media $handheld {
    width: 100%;
    font-size: 16px;
  }

  @media $small-mobile {
    font-size: 12px;
  }

  td,
  th {
    text-align: center;
    border-bottom: 1px solid #a6a3a1;
  }

  th {
    background: $yellow;
    color: $white;
    padding: 10px 30px;
    font-weight: normal;

    @media $small-mobile {
      padding: 5px 10px;
    }
  }

  td {
    padding: 20px 30px;

    @media $small-mobile {
      padding: 7px 10px;
    }
  }

  tr:last-child td {
    border-bottom: 2px solid #433d39;
  }
}

.two-schemes {
  display: flex;
  max-width: 100%;
  width: 1176px;

  .item {
    min-width: 0;

    table,
    tbody,
    tr,
    td {
      display: block;
      width: 100%;
    }
  }

  @media $handheld {
    display: block;
  }

  .item + .item {
    margin-left: 3vw;

    @media $handheld {
      margin-left: 0;
      margin-top: 75px;
    }
  }
}

.whatsapp-float {
  position: fixed;
  right: 30px; bottom: 30px;
  z-index: 100;
  width: 50px; height: 50px;
  background: url(/i/whatsapp-icon.png) no-repeat 50% / contain;
}

@media $small-handheld {
  .footer-email-container {
    float: none;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .footer-email {
    text-align: center;
  }
}

@media $small-mobile {
  .mobile-noupper {
    text-transform: none;
  }

  .mobile-nn {
    font-weight: normal;
  }
}
