.carousel-wrapper {
  position: relative; }
.carousel-container {
  position: relative;
  overflow: hidden;
  margin-left: auto!important;
  margin-right: auto!important; }
ul.carousel {
  width: 20000em;
  position: absolute;
  margin: 0;
  padding: 0; }
.carousel-vertical ul.carousel {
  width: auto;
  height: 20000em; }
ul.carousel li {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0; }
.carousel-vertical ul.carousel li {
  float: none; }
.carousel-controls {
  display: block;
  position: absolute;
  background-position: 0 0;
  background-repeat: no-repeat;
  top: 50%; }
.carousel-controls.carousel-prev {
  left: 0; }
.carousel-controls.carousel-next {
  right: 0; }
.carousel-vertical .carousel-controls {
  left: 50%; }
.carousel-vertical .carousel-controls.carousel-prev {
  top: 0; }
.carousel-vertical .carousel-controls.carousel-next {
  top: 100%; }

.products-carousel {
  position: relative;
  @media $small-handheld {
    margin-left: -10px;
    margin-right: -10px;
  }
}
.products-carousel .carousel-container {
  width: 375px;
  height: 185px;
  @media $small-handheld {
    width: 100%;
  }
}
.products-carousel ul.carousel {}
.products-carousel ul.carousel li {
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-right: 9px;
  width: 87px;
  .vxodnye_dveri & {
    width: 119px;
  }
  @media $small-handheld {
    &:first-child {
      margin-left: 10px;
    }
  }
  a {
    display: block;
    height: 185px;
    line-height: 185px;
    text-decoration: none;
    img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle; }
  }
  span {
    display: inline-block;
    position: relative;
    max-width: 100%;
    max-height: 100%;
    line-height: normal;
    vertical-align: middle; }
  &.active {
    span {
      &:after {
        box-sizing: border-box;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border: 5px solid $yellow;
        position: absolute;
        left: 0;
        top: 0; }
    }
  }
}
.products-carousel .carousel-controls {
  box-sizing: border-box;
  display: none;
  width: 41px;
  height: 41px;
  top: 50%;
  margin-top: -25px;
  background: url(/i/carousel-arrows.png) no-repeat rgba($yellow, .72) 50% -2px;
  border: 2px solid $yellow;
  &.disabled {
    background-color: rgba($black, .72);
    border-color: $black;
    cursor: default; }
  &:active:not(.disabled) {
    margin-top: -24px; }
  @media $small-handheld {
    display: none !important;
  }
}
.products-carousel .carousel-controls.carousel-prev {
  left: -15px; }
.products-carousel .carousel-controls.carousel-next {
  background-position: 50% -43px;
  right: -15px; }

.variants-carousel {
  position: relative;
  @media $not-small-handheld {
    padding: 0 49px;
  }
  @media $small-handheld {
    margin-left: -10px;
    margin-right: -10px;
  }
}
.variants-carousel .carousel-container {
  width: 278px;
  height: 65px;
  @media $small-handheld {
    width: 100%;
  }
}
.variants-carousel ul.carousel {}
.variants-carousel ul.carousel li {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-right: 6px;
  width: 65px;
  height: 65px;
  @media $small-handheld {
    &:first-child {
      margin-left: 10px;
    }
  }
  a {
    overflow: hidden;
    display: block;
    height: 65px;
    line-height: 65px;
    text-decoration: none;
    img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: top; }
  }
  &.active {
    border: 8px solid $yellow;
    a {
      height: 49px;
      line-height: 49px; }
    img {
      position: absolute;
      left: -8px;
      top: -8px;
      width: 64px;
      max-width: none;
      max-height: none; }
  }
}
.variants-carousel .carousel-controls {
  box-sizing: border-box;
  display: none;
  width: 41px;
  height: 41px;
  top: 50%;
  margin-top: -21px;
  background: url(/i/carousel-arrows.png) no-repeat rgba($yellow, .72) 50% -2px;
  border: 2px solid $yellow;
  &.disabled {
    background-color: rgba($black, .72);
    border-color: $black;
    cursor: default; }
  &:active:not(.disabled) {
    margin-top: -20px; }
  @media $small-handheld {
    display: none !important;
  }
}
.variants-carousel .carousel-controls.carousel-prev {
  left: 0; }
.variants-carousel .carousel-controls.carousel-next {
  background-position: 50% -43px;
  right: 0; }

.doors-carousel {
  position: relative;
  .carousel-container {
    width: 100%; }
  .carousel {
    position: relative;
    width: 300000px;
    li {
      a {
        display: block;
        img {
          max-width: 100%; }
      }
    }
  }
}
.doors-carousel .carousel-controls {
  box-sizing: border-box;
  display: none;
  width: 41px;
  height: 41px;
  top: 50%;
  margin-top: -25px;
  background: url(/i/carousel-arrows.png) no-repeat rgba($yellow, .72) 50% -2px;
  border: 2px solid $yellow;
  &.disabled {
    background-color: rgba($black, .72);
    border-color: $black;
    cursor: default; }
  &:active:not(.disabled) {
    margin-top: -24px; }
  @media $small-handheld {
    display: none !important;
  }
}
.doors-carousel .carousel-controls.carousel-prev {
  left: -15px; }
.doors-carousel .carousel-controls.carousel-next {
  background-position: 50% -43px;
  right: -15px; }
@media $small-handheld {
  .doors-carousel .carousel-container {
    height: 185px;
    width: 100%;
  }
  .doors-carousel ul.carousel {}
  .doors-carousel ul.carousel li {
    position: relative;
    overflow: hidden;
    text-align: center;
    margin-right: 9px;
    width: 87px;
    &:first-child {
      margin-left: 10px;
    }
    a {
      display: block;
      height: 185px;
      line-height: 185px;
      text-decoration: none;
      img {
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle; }
    }
    span {
      display: inline-block;
      position: relative;
      max-width: 100%;
      max-height: 100%;
      line-height: normal;
      vertical-align: middle; }
    &.active {
      span {
        &:after {
          box-sizing: border-box;
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          border: 5px solid $yellow;
          position: absolute;
          left: 0;
          top: 0; }
      }
    }
  }
}
