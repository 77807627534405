.ps-container .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    bottom: 3px;
    height: 8px;
    opacity: 1; }
.ps-container .ps-scrollbar-x-rail.in-scrolling {
    opacity: 0.9; }
.ps-container .ps-scrollbar-y-rail {
    position: absolute;
    right: 0;
    width: 17px;
    opacity: 1;
    background: $white; }
.ps-container .ps-scrollbar-y-rail:hover,
.ps-container .ps-scrollbar-y-rail.hover {
    background-color: #eee;
    opacity: 0.9; }
.ps-container .ps-scrollbar-y-rail.in-scrolling {
    opacity: 0.9; }
.ps-container .ps-scrollbar-x {
    position: absolute;
    bottom: 0;
    height: 8px;
    background-color: #aaa; }
.ps-container.ie6 .ps-scrollbar-x {
    font-size: 0; }
.ps-container .ps-scrollbar-y {
    position: absolute;
    right: 0;
    width: 17px;
    background-color: #aaa; }
.ps-container.ie .ps-scrollbar-y {
    font-size: 0; }
.ps-scrollbar-y-rail {
  border: 1px solid #b5b5b5;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 19px;
    height: 19px;
    left: -1px;
    z-index: 10; }
  &:before {
    top: -1px;
    background: $black url(/i/scroll-arrow-up.png) 50% no-repeat; }
  &:after {
    bottom: -1px;
    background: $black url(/i/scroll-arrow-down.png) 50% no-repeat; }
}