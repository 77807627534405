
// News

.news-section {
  min-height: calc(100vh - 121px);
  // margin-top: 41px;
  background: url(/i/dark-wood.jpg) no-repeat fixed 50% 0 / cover;
  box-sizing: boder-box;
  padding: 40px 0;

  @media $mobile {
    margin-top: -17px;
    padding-top: 25px;
  }
}

.news-sections-list {
  @media $not-mobile {
    margin-bottom: 33px;
  }

  @media $mobile {
    margin-bottom: 20px;
  }

  ul {
    display: flex;
    justify-content: space-between;

    @media $mobile {
      justify-content: center;
    }
  }

  li {
    list-style: none;
    max-width: 264px;
    @media $not-mobile {
      width: calc(25% - 10px);
    }
  }

  a {
    display: block;
    text-align: center;
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 27px;
    font-weight: bold;
    font-family: Arial;
    transition: background-color .25s ease;

    @media $not-mobile {
      height: 57px;
      line-height: 57px;
      background: #a1a1a1;

      &:hover {
        background: $yellow;
      }
    }

    @media $mobile {
      font-size: 24px;
      font-weight: normal;
    }
  }

  @media $not-mobile {
    .active a {
      background: $yellow;
    }
  }
}

.news-list .items {
  .news-announce {
    width: calc(33.33% - 16px);
    background: $white;
    margin-bottom: 23px;
    white-space: normal;
    text-align: left;

    @media $mobile {
      width: 100%;
      margin-bottom: 30px;

      .news-section & {
        margin-bottom: 10px;
      }
    }
  }

  .gutter-sizer {
    width: 24px;
  }

  .news-announce--image {
    img {
      max-width: 100%;
    }
  }

  .news-announce--date {
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%; */
    height: 27px;
    line-height: 27px;
    box-sizing: border-box;
    padding: 0 15px;
    text-align: right;
    color: $white;
    background: rgba(#606060, .55);
    font-size: 13px;
  }

  .news-announce--inner {
    padding: 10px 36px 20px;
    font-family: Arial;
  }

  .news-announce--name {
    font-size: 22px;
    line-height: 1.2;
    margin-bottom: 10px;

    a {
      color: $black;
      text-decoration: none;
    }
  }

  .news-announce--text {
    font-size: 17px;
    line-height: 1.2;
    margin-bottom: 15px;
  }

  .news-announce--more {
    height: 25px;
    line-height: 25px;
    padding: 0 15px;
    border-right: 2px solid $yellow;
    background: #9b9b9a;
    color: $white;
    font-size: 12px;
    text-transform: uppercase;
    position: relative;

    &:after {
      content: '';
      display: block;
      height: 100%;
      background: $yellow;
      position: absolute;
      right: 0;
      top: 0;
      transition: max-width .25s ease;
      max-width: 0;
      width: 7px;
    }

    &:hover {
      &:after {
        max-width: 7px;
      }
    }
  }
}

.news-list.another-news {
  clearfix();
  background: url(/i/dark-wood.jpg) no-repeat 50% 0 / cover;
  padding: 35px 0;

  @media $mobile {
    padding: 20px 0 10px;

    .h1 {
      color: $white;
      font-size: 24px;
      text-align: center;
    }
  }

  .items {
    .news-announce {
      float: left;
      position: relative;

      @media $not-mobile {
        + .news-announce {
          margin-left: 24px;
        }
      }
    }
  }

  .tos-pagination {
    a {
      width: 54px; height: 6px;
      background: #9c9c9b;
      border-radius: 0;
      margin: 0;

      + a {
        margin-left: 6px;
      }

      &.tos-selected {
        background: $yellow;
      }
    }
  }

  .tos-slide {
    vertical-align: top;
  }
}

.news--date {
  background: #a2a3a3;
  height: 27px;
  line-height: 27px;
  padding: 0 15px;
  color: $white;
  font-size: 13px;
}

.news {
  padding-bottom: 30px;

  img {
    max-width: 100%;
  }
}

.news-list.index-news {
  background: url(/i/light-wood.jpg) no-repeat 50% 0 / cover;
}

@media $small-handheld {
  .news-wrapper {
    padding-top: 0 !important;

    img {
      max-width: 100%;
      width: auto !important;
      height: auto !important;
    }

    h1 {
      font-size: 20px;
      line-height: 1.2;
      margin-bottom: 15px;
    }
  }
}