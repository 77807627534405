
// Colors section
$black = #575756;
$white = #fff;
$red = #d51c21;
$yellow = #ffa409;

$body-background = url(/i/body-bg.png) $white;
$text-color = $black;
$link-color = $yellow;
$hovered-link-color = lighten($yellow, 10);


// Fonts section
$fonts = MuseoSansCyrl, Arial, sans-serif;
$text-size = 17px;
$line-height = 120%;


// Structure
$wrapper-max-width = 1245px;
$wrapper-min-width = 460px;
$header-height = 80px;
$footer-height = 260px;
$footer-max-height = 420px;


// Media queries
$media-vars = json("../../js/src/media-queries-config.json", { hash: true });

$small-mobile-breakpoint = unit($media-vars.mobile.small, 'px');
$mobile-breakpoint = unit($media-vars.mobile.portrait, 'px');
$landscape-mobile-breakpoint = unit($media-vars.mobile.landscape, 'px');
$landscape-tablet-breakpoint = unit($media-vars.tablet.landscape, 'px');
$portrait-tablet-breakpoint = unit($media-vars.tablet.portrait, 'px');
$small-notebook-breakpoint = unit($media-vars.notebook.small, 'px');
$notebook-breakpoint = unit($media-vars.notebook.normal, 'px');

$small-mobile = "only screen and (max-width: $small-mobile-breakpoint)";
$not-small-mobile = "only screen and (min-width: $small-mobile-breakpoint + 1px)";
$mobile = "only screen and (max-width: $mobile-breakpoint)";
$not-mobile = "only screen and (min-width: $mobile-breakpoint + 1px)";
$landscape-mobile = "only screen and (max-width: $landscape-mobile-breakpoint)";
$not-landscape-mobile = "only screen and (min-width: $landscape-mobile-breakpoint + 1px)";
$tablet = "only screen and (max-width: $landscape-tablet-breakpoint) and (min-width: $portrait-tablet-breakpoint)";
$landscape-tablet = "only screen and (max-device-width: $landscape-tablet-breakpoint) and (min-device-width: $portrait-tablet-breakpoint) and (orientation: landscape)";
$portrait-tablet = "only screen and (max-device-width: $landscape-tablet-breakpoint) and (min-device-width: $portrait-tablet-breakpoint) and (orientation: portrait)";
$small-notebook = "only screen and (max-width: $small-notebook-breakpoint) and (min-width: $landscape-tablet-breakpoint + 1px)";
$notebook = "only screen and (max-width: $notebook-breakpoint) and (min-width: $landscape-tablet-breakpoint + 1px)";
$desktop = "only screen and (min-width: $landscape-tablet-breakpoint + 1px)";
$handheld = "only screen and (max-width: $landscape-tablet-breakpoint)";
$small-handheld = "only screen and (max-width: $portrait-tablet-breakpoint)";
$not-small-handheld = "only screen and (min-width: $portrait-tablet-breakpoint + 1px)";

@media $mobile {
  .not-mobile {
    display: none !important;
  }
}

@media $small-handheld {
  .not-small-handheld {
    display: none !important;
  }
}

@media $not-mobile {
  .only-mobile {
    display: none !important;
  }
}

@media $not-small-handheld {
  .only-small-handheld {
    display: none !important;
  }
}

@media $handheld {
  .only-desktop {
    display: none !important;
  }
}

@media $desktop {
  .only-handheld {
    display: none !important;
  }
}
