body {
  font-family: $fonts;
  font-size: $text-size;
  line-height: $line-height;
  color: $text-color;
  background: $body-background;
  overflow-x: hidden;
  /* min-width: $wrapper-min-width;  */
}

a {
  color: $link-color;
  &:hover {
    color: $hovered-link-color;
  }
}

h1, h1 a, .h1, .h1 a {
  font-size: 36px;
  line-height: 120%;
  color: $black;
  margin-bottom: 15px;
  text-decoration: none;
  font-weight: normal; }
h2, h2 a, .h2, .h2 a {
  font-size: 18px;
  line-height: 120%;
  color: $black;
  margin-bottom: 15px;
  text-decoration: none;
  font-weight: normal; }
h3, h3 a, .h3, .h3 a {
  font-size: 14px;
  line-height: 120%;
  color: $black;
  margin-bottom: 15px;
  text-decoration: none; }
h4, h4 a, .h4, .h4 a {
  font-size: 13px;
  line-height: 120%;
  color: $black;
  margin-bottom: 10px;
  text-decoration: none; }

.inp, textarea, select {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 21px;
  color: $black;
  padding: 17px 20px;
  background: $white;
  border: 1px solid $black; }

.fileinput-btn {
  position: relative;
  .btn {
    position: absolute;
    left: 0;
    top: 0; }
  input {
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    height: 55px;
    line-height: 55px;
    padding: 0 20px;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 250px; }
  .filename {
    position: absolute;
    left: 265px;
    top: 18px; }
}

/*   -=========== Forms ===========-   */
.form {
  // width: 825px;
  @media $small-handheld {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  * {
    font-family: $fonts; }
  .form-row:before, .form-row:after {
    content: " ";
    display: table; }
  .form-row:after {
    clear: both; }
  label {
    font-size: 16px;
    @media $not-small-handheld {
      float: left;
      width: 230px;
      padding: 22px 15px 0 0;
    }
    @media $small-handheld {
      display: block;
      margin-bottom: 7px;
      font-size: 14px;
    }
  }
  .form-field {
    @media $not-small-handheld {
      float: left;
      width: 580px;
    }
    label {
      float: none;
      width: 100%;
      padding-right: 0; }
    .inp, select, textarea {
      width: 100%; }
    .form-size-half {
      width: 48%; }
    .form-size-third {
      width: 32%; }
    .form-size-quarter {
      width: 24%; }
    .form-size-auto {
      width: auto; }
    @media $small-handheld {
      .inp {
        padding: 0 8px;
        line-height: 30px;
        font-size: 14px;
      }
      .newListSelected {
        .selectedTxt {
          padding: 0 33px 0 15px;
          height: 30px;
          line-height: 28px;
          font-size: 14px;
          text-transform: uppercase;
          &:after {
            width: 28px; height: 100%;
            top: 0; right: 0;
            margin: 0;
            background: #55575b url(/i/select-arrow-mini.png) no-repeat 50%;
          }
        }
        .SSContainerDivWrapper {
          top: 28px !important;
        }
      }
    }
  }
  .form-separator {
    display: inline-block;
    text-align: center;
    width: 4%; }
  .form-hint {
    @media $not-small-handheld {
      padding-left: 245px;
    }
    font-size: 12px; }
  .form-submit {
    @media $not-small-handheld {
      width: 825px;
      text-align: right;
    }
    @media $small-handheld {
      text-align: center;
      .btn {
        font-size: 16px;
        line-height: 38px;
        height: 38px;
        padding: 0 25px;
      }
    }
  }
  span.required,
  .required span {
    color: $yellow; }
  .error {
    input, select, textarea {
      border: 1px solid $red; }
  }
  .errorMessage {
    font-size: 12px;
    color: #8A004B;
    padding: 5px 20px;
    background: #F5DCEA;
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }
  .success-message {
    padding: 10px;
    font-weight: bold;
    text-align: center; }
}

/*   -=========== Useful classes ===========-   */
.hova a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.unhova a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.nova a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.left {
  float: left;
  margin: 0 15px 0 0; }
.right {
  float: right;
  margin: 0 0 0 15px; }
.fl {
  float: left; }
.fr {
  float: right; }
.auto {
  width: 100%;
  height: auto; }

.mrk1 {
  //background: url(mrk1.gif) no-repeat 0 4px;
  padding-left: 12px; }

.hr1 {
  background: $yellow;
  font-size: 2px;
  line-height: 2px;
  height: 2px;
  margin: 10px 0 15px 0;
  &.grey {
    height: 1px;
    line-height: 1px;
    font-size: 1;
    background: $black; }
  &.cropped {
    width: 70%;
    margin-left: auto;
    margin-right: auto; }
  &.tight {
    height: 1px;
    line-height: 1px;
    font-size: 1px; }
}

.l-main {
  float: left;
  width: 49%;
  margin-right: 2%; }
.r-main {
  float: right;
  width: 49%; }

.block-center {
  overflow: hidden;
  position: relative;
  width: 100%; }
.block-center-div {
  position: relative;
  float: left;
  left: 50%; }
.block-center-div-div {
  position: relative;
  float: left;
  right: 50%; }

.text-container:before, .text-container:after, .nofloat:before, .nofloat:after {
  content: " ";
  display: table; }
.text-container:after, .nofloat:after {
  clear: both; }
.text-container {
  margin-bottom: 10px; }
.no-overflow {
  overflow: hidden; }
.text-container {
  p {
    padding-top: 20px;
    &:first-child {
      padding-top: 0; }
  }
  h2, h3, h4, h5, h6 {
    padding-top: 20px;
  }
  ul {
    padding-top: 20px;
    li {
      list-style: disc outside;
      margin-bottom: 5px; }
  }
}

.center {
  text-align: center; }
.nowrap {
  white-space: nowrap; }

/*   -=========== Classes for chainable applying ===========-   */
.nn {
  font-weight: normal; }
.bb {
  font-weight: bold; }
.italic {
  font-style: italic; }
.uppercase {
  text-transform: uppercase; }
.m3 {
  margin-bottom: 3px;
}
.m7 {
  margin-bottom: 7px;
}
for $i in (0..20) {
  .m{$i * 5} {
    margin-bottom: 5px * $i;
  }
}
for $i in (8..40) {
  .s{$i} {
    font-size: unit($i, 'px');
    line-height: 1.2;
  }
}

@media $mobile {
  .mobile-m20 {
    margin-bottom: 20px;
  }
}

.white {
  color: $white !important; }
.yellow {
  color: $yellow !important; }
.marked {
  background: $yellow;
  color: $white;
  padding: 0 5px; }

.arial {
  font-family: Arial; }

.postern {
  background-color: $white;
  padding-bottom: 100px;
  width: 100vw;
  box-sizing: border-box;

  @media $small-mobile {
    padding-bottom: 40px;
  }

  img {
    max-width: 100%;
  }

  .wrapper {
    padding: 0 60px;
    max-width: 1920px;
    box-sizing: border-box;

    @media $small-mobile {
      padding: 0 20px;
    }
  }

  h2 {
    font-size: 40px;
    margin-bottom: 60px;
    color: #000;

    @media $small-mobile {
      margin-bottom: 30px;
      font-size: 20px;
    }
  }
}

.card-top {
  display: grid;
  grid-template-columns: 43% 57%;
  margin-bottom: 50px;

  @media $handheld {
    grid-template-columns: 1fr;
  }

  @media $small-mobile {
    margin-bottom: 30px;
  }

  &.wrapper {
    padding: 0;
  }

  h1 {
    color: $white;

    @media $small-mobile {
      font-size: 24px;
    }
  }

  .breadcrumbs {
    background-color: $white;
    margin: 0 -60px;
    padding-left: 60px;
    padding-top: 50px;
    padding-bottom: 50px;
    height: 100%;
    display: flex;

    @media $handheld {
      order: -1;
      background-color: transparent;
      background-image: none;
    }

    @media $mobile {
      margin: 0 -30px;
      padding-left: 30px;
    }

    @media $small-mobile {
      padding-top: 0;
    }

    ul {
      margin: auto 0;
    }

    a,
    li {
      text-transform: none;
      font-size: 20px;

      @media only screen and (max-width: 1367px) {
        font-size: 16px;
      }

      @media $handheld {
        color: #fff;
      }
    }

    li:not(:nth-last-child(2)) {
      @media $mobile {
        display: none;
      }
    }

    li:nth-last-child(2) a {
      @media $mobile {
        font-size: 12px;
        text-transform: uppercase;
        margin-right: 0;
        padding-left: 20px;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: 7px;
          height: 7px;
          border-bottom: 1px solid #fff;
          border-left: 1px solid #fff;
          top: 0;
          bottom: 0;
          margin: auto 0;
          transform: rotate(45deg);
        }

        &::after {
          content: none;
        }
      }
    }

    a {
      margin-right: 90px;

      @media only screen and (max-width: 1367px) {
        margin-right: 50px;
      }

      &::after {
        content: '/';
        transform: translateY(0) translateX(40px);
        width: unset;
        height: unset;

        @media only screen and (max-width: 1367px) {
          transform: translateY(0) translateX(24px);
        }
      }
    }
  }
}

.card-top__info {
  padding: 76px 58px 0;
  background: url('/i/postern-top-background.png') 100% / contain no-repeat;
  background-color: #2b375e;
  color: $white;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1367px) {
    padding: 40px 58px 0;
    background-position-x: 113%;
  }

  @media $handheld {
    padding-top: 0;
    background-image: none;
  }

  @media $small-mobile {
    padding: 40px 30px 0;
  }
}

.card-top__collection {
  display: flex;
  gap: 70px;
  margin-bottom: 60px;

  @media $handheld {
    margin-bottom: 10px;
  }

  img {
    width: 48px;
  }
}

.collections-list {
  display: flex;
  flex-direction: column;
}

.collection-block:has(+ .collection-hidden) {
  order: 1;
}

.collection-hidden {
  display: flex;
  padding-top: 0 !important;

    @media only screen and (max-width: 897px) {
      flex-direction: column;
    }

    @media $small-mobile {
      position: relative;
    }

  .card-top__info {
    background-image: none;
    min-width: 670px;
    box-sizing: border-box;

    @media only screen and (max-width: 1367px) {
      min-width: 545px;
    }

    @media $handheld {
      padding: 40px 58px 0;
    }

    @media $small-mobile {
      min-width: unset;
      padding: 40px 25px 0;
    }
  }

  .card-top__collection {
    gap: 46px;

    @media $small-mobile {
      margin-bottom: 45px;
    }
  }

  .card-top__collection-name {
    font-size: 18px;
    text-transform: uppercase;

    @media $small-mobile {
      font-size: 14px;
    }

    &::before {
      @media $small-mobile {
        left: -23px;
      }
    }
  }

  .card-top__collection-name span {
    font-size: 42px;

    @media only screen and (max-width: 1367px) {
      font-size: 36px;
    }

    @media $small-mobile {
      font-size: 24px;
    }
  }

  .card-top__info-wrapper {
    gap: 100px;
  }

  .card-top__text span {
    font-size: 36px;
    margin-bottom: 20px;
    display: block;
    line-height: 120%;

    @media only screen and (max-width: 1367px) {
      font-size: 25px;
    }

    @media $small-mobile {
      margin-bottom: 0;
    }
  }

  .card-top__variants {
    @media only screen and (max-width: 897px) {
      padding-top: 216px;
    }

    @media $small-mobile {
      position: absolute;
      bottom: 20px;
      z-index: 1;

      &::after {
        width: 100px;
        background-color: #2b375e;
        content: '';
        position: absolute;
        top: 0;
        bottom: -20px;
        left: -30px;
        z-index: -1;
      }
    }

    p {
      @media only screen and (max-width: 897px) {
        font-size: 14px;
        letter-spacing: 10.6px;
        left: -75px;
      }
    }
  }
}

.collection-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.collection-hidden__more {
  width: 100%;
  max-width: 170px;
  align-self: center;
  box-sizing: border-box;
  font-size: 13px;
  height: 36px;
  border-radius: 50px;
  color: $white;
  background-color: transparent;
  padding: 0 80px 0 20px;
  display: flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  text-decoration: none;
  border: 0.6px solid $white;
  margin-top: 70px;

  @media $small-mobile {
    margin-top: 30px;
  }

  &:hover,
  &:focus {
    color: $black;
    background-color: $white;

    &::after {
      filter: brightness(0) saturate(100%) invert(19%) sepia(10%) saturate(3308%) hue-rotate(189deg) brightness(94%) contrast(90%);
    }
  }

  &::after {
    position: absolute;
    content: '';
    width: 41px;
    height: 14px;
    background: url('/i/arrow-white-long.svg') 50% / contain no-repeat;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.card-top__collection-name {
  display: flex;
  flex-direction: column;
  font-weight: $light;
  position: relative;

  &::before {
    position: absolute;
    width: 1px;
    height: 64px;
    background-color: $white;
    content: '';
    left: -35px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  span {
    font-size: 4.5rem;
    text-transform: none;
    font-weight: $medium;
    line-height: 100%;
  }
}

.card-top__decor {
  img {
    display: block;
    height: 100%;
    object-fit: cover;

    @media $small-mobile {
      width: 100%;
    }
  }
}

.card-top__info-wrapper {
  display: flex;
  gap: 48px;
  margin-bottom: 46px;
}

.card-top__variants {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  padding-top: 250px;

  p {
    transform: rotate(270deg);
    font-size: 17px;
    font-weight: $medium;
    letter-spacing: 13.6px;
    text-transform: uppercase;
    position: absolute;
    left: -90px;
    top: 76px;
  }

  span {
    font-size: 23px;
    font-style: normal;
    font-weight: $medium;
    line-height: 100%;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      background-color: $white;
      top: -25px;
    }
  }
}

.card-top__text {
  max-width: 462px;
  font-size: 20px;
  line-height: 26px;

  @media only screen and (max-width: 1367px) {
    font-size: 16px;
  }

  @media $small-mobile {
    font-size: 13px;
    line-height: 20px;
  }
}

.postern__price-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-bottom: 50px;

  @media $mobile {
    grid-template-columns: 1fr;
  }

  @media $small-mobile {
    margin-bottom: 30px;
  }
}

.opened-anchors {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 70px;

  @media $handheld {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.opened-anchors__item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 60px;
  text-decoration: none;
  color: black;
  font-size: 15px;
  border: 1px solid black;
  border-radius: 30px;
  transition: all 250ms ease;

  @media only screen and (max-width: 1366px) {
    width: 180px;
    height: 50px;
  }

  @media $small-mobile {
    width: 46%;
  }

  &:hover {
    background-color: black;
    color: white;
  }
}

.postern__price-text {
  font-size: 20px;
  color: #6d6e71;
  line-height: 26px;

  @media $mobile {
    display: none;
  }
}

.postern__price-right {
  padding: 36px 60px 30px 36px;
  background-color: #f5f5f5;
  margin-right: -60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;

  @media $handheld {
    grid-template-columns: 1fr;
  }

  @media $mobile {
    margin-left: -60px;
  }

  @media $small-mobile {
    justify-items: center;
    margin: 0 -20px;
  }
}

.postern__price {
  font-size: 27px;
  display: flex;
  gap: 10px;
}

.text-hint {
  position: relative;
}

.text-hint__toggler {
  display: block;
  color: black !important;
  text-decoration: none;

  &::before {
    content: 'i';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px; height: @width;
    border-radius: 50%;
    border: 1px solid black;
    font-size: 17px;
    line-height: 0;
    margin-top: -15px;
    transition: all 180ms ease;
  }

  &:hover,
  &:active,
  .is-expanded & {
    &::before {
      background-color: black;
      color: $white;
    }
  }
}

.text-hint__content {
  position: absolute;
  left: 100%;
  bottom: 135%;
  z-index: 1;
  box-shadow: 0 2px 5px 0 rgba($black, 0.15);
  font-size: 13px;
  font-weight: 300;
  opacity: 0;
  transition: all 180ms ease;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 25px;
  background-color: $white;
  min-width: 210px;

  @media $handheld {
    left: auto;
    right: 0;
  }

  &::before,
  &::after {
    content: none;
  }

  .is-expanded & {
    padding: 10px 15px;
    opacity: 1;
  }
}

.text-hint__close {
  display: block;
  width: 17px; height: @width;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 19px;
    height: 1px;
    top: 50%;
    translate: -50% -50%;
    background-color: $black;
  }

  &::before {
    transform: rotate(45deg);
    left: 50%;
  }

  &::after {
    transform: rotate(-45deg);
    left: 50%;
  }
}

.postern__price-buy {
  width: 100%;
  max-width: 368px;
  align-self: center;
  box-sizing: border-box;
  font-size: 27px;
  height: 75px;
  border-radius: 50px;
  color: $white;
  background-color: #000;
  padding: 0 106px 0 40px;
  display: flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  text-decoration: none;

  @media only screen and (max-width: 1367px) {
    font-size: 20px;
  }

  @media $small-mobile {
    order: 1;
  }

  &:hover,
  &:focus {
    color: $black;
    background-color: transparent;
    border: 0.6px solid $black;

    &::after {
      filter: brightness(0) saturate(100%);
    }
  }

  &::after {
    position: absolute;
    content: '';
    width: 66px;
    height: 14px;
    background: url('/i/arrow-white-long.svg') 50% / contain no-repeat;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.postern__price-articul {
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 13px;
  color: #6d6e71;
  grid-column: 1 / -1;

  span {
    color: #000;
  }
}

.postern__parametrs {
  margin-bottom: 80px;

  &.wrapper {
    padding: 0;
  }

  @media $small-mobile {
    margin-bottom: 30px;
  }

  img {
    @media $small-mobile {
      width: 100%;
    }
  }
}

.postern__variants {
  margin-bottom: 100px;
}

.postern-tabs__nav {
  display: flex;
  list-style-type: none;
  padding-bottom: 14px;
  border-bottom: 3px solid #d9d9d9;
  max-width: 725px;
  margin: 0 auto 60px auto;

  li {
    flex: 1;
    text-align: center;
    position: relative;
    list-style-type: none !important;

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 3px;
      background-color: #000;
      bottom: -17px;
      opacity: 0;
      left: 0;
      transition: opacity .5s ease;
    }

    &.is-active::before {
      opacity: 1;
    }
  }

  a {
    color: #3b3b3b;
    font-size: 14px;
    text-decoration: none;
  }
}

.postern__tabs-slider {
  margin-bottom: 40px;
}

.postern__tabs-slider .slick-list {
  margin-left: -50px;

  @media $small-mobile {
    margin-left: -25px;
  }
}

.postern__tabs-slider__item {
  margin-left: 50px;
  text-align: center;

  @media $small-mobile {
    margin-left: 25px;
  }

  img {
    margin: 0 auto;
  }

  p {
    margin-top: 15px;

    @media $mobile {
      font-size: 13px;
    }
  }
}

.slider-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  .slick-arrow {
    width: 36px;
    height: 36px;
    position: relative;
    top: 50%;
    font-size: 0;
    transition: all 250ms ease;
    z-index: 1;
    border-radius: 50%;
    border: 1px solid #000;
    background-color: #fff;
    flex-shrink: 0;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 13px;
      height: 8px;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background: url(/i/arrow-white-short.svg) 100% / cover no-repeat;
      filter: brightness(0) saturate(100%) invert(0%) sepia(90%) saturate(7459%) hue-rotate(60deg) brightness(88%) contrast(112%);
    }

    &:hover {
      background-color: #000;
    }

    &:hover::before {
      filter: unset;
    }

    &.slick-disabled {
      border-color: #acacac;

      &::before {
        filter: brightness(0) saturate(100%) invert(73%) sepia(6%) saturate(13%) hue-rotate(352deg) brightness(94%) contrast(87%);
      }
    }
  }

  .slick-prev {
    margin-right: 20px;

    &::before {
      transform: scaleX(-1);
    }
  }

  .slick-arrow + .postern__tabs-slider__controls-line {
    max-width: 770px;
    width: 100%;
    height: 1px;
    background-color: #000;
    order: 2;
    margin: 0 46px;

    @media $small-mobile {
      margin: 0 0 0 20px;
    }
  }
}

.postern__tabs-content__info {
  display: grid;
  grid-template-columns: 1fr auto;

  @media only screen and (max-width: 1500px) {
    grid-template-columns: auto auto;
    gap: 15px;
  }

  @media $handheld {
    grid-template-columns: auto;
    gap: 20px;
  }

  @media $small-mobile {
    margin-bottom: 50px;
  }
}


.postern__tabs-content__info-left {
  max-width: 732px;
  font-size: 20px !important;
  color: #6d6e71;

  @media $mobile {
    font-size: 13px !important;
  }

  span {
    color: #000;

    @media $mobile {
      font-size: 15px;
    }
  }
}

.postern__tabs-content__info-right {
  span:not(.remark) {
    display: block;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    color: black;
  }

  span.remark {
    display: block;
    margin-left: auto;
    font-size: 14px;
    font-weight: 300;
    color: #6d6e71;
    text-align: end;
    margin-top: 20px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  picture {
    display: block;
    text-align: end;
  }

  img {
    @media $small-mobile {
      width: 100%;
    }
  }
}

.postern__advantages-block {
  display: flex;
  gap: 30px;
  margin-bottom: 100px;

  @media $handheld {
    flex-direction: column;
  }

  @media $small-mobile {
    gap: 15px;
    margin-bottom: 30px;
  }
}

.postern__advantages-block.postern__advantages-block--reverse {
  flex-direction: row-reverse;

  @media $handheld {
    flex-direction: column;
  }
}

.postern__advantages-block__left {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 60px;
  align-content: flex-start;
  row-gap: 30px;
  width: 50%;

  @media $handheld {
    gap: 10px;
    width: 100%;
  }

  @media $small-mobile {
    align-items: flex-end;
  }

  & > div {
    color: #2a365c;
    font-size: 60px;
    line-height: normal;

    @media $small-mobile {
      font-size: 20px;
    }
  }

  & > p {
    grid-column: 2 / 3;
    color: #6D6E71;
    font-size: 20px;

    @media $handheld {
      grid-column: 1 / -1;
    }

    @media $small-mobile {
      font-size: 13px;
    }
  }

  & > span {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    color: #000;
    line-height: normal;

    @media $small-mobile {
      font-size: 15px;
    }
  }
}

.postern__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  margin-bottom: 100px;

  @media $mobile {
    grid-template-columns: 1fr;
    row-gap: 40px;
  }

  @media $small-mobile {
    margin-bottom: 60px;
  }

  h2 {
    grid-column: 1 / -1;
  }
}

.postern__content-info {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 30px;
  margin-top: 46px;

  @media $handheld {
    grid-template-columns: auto;
  }

  img {
    @media $small-mobile {
      width: 100%;
    }
  }

  span {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #000;
    display: block;
  }

  p {
    font-size: 20px;
    color: #000;
    line-height: 26px;

    @media only screen and (max-width: 1501px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.postern__furniture {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  margin-bottom: 100px;

  @media $handheld {
    .postern__tabs-content__info-right {
      grid-column: 1 / -1;
    }
  }

  @media $mobile {
    grid-template-columns: 1fr;
    row-gap: 40px;
  }

  @media $small-mobile {
    margin-bottom: 30px;
  }

  h2 {
    grid-column: 1 / -1;

    @media $small-mobile {
      margin-bottom: 0;
    }
  }
}

.postern__furniture-block {
  color: #6d6e71;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media $small-mobile {
    font-size: 13px;
  }

  span {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 15px;

    @media $small-mobile {
      font-size: 15px;
    }
  }

  ul {
    margin-bottom: 30px;

    @media $small-mobile {
      margin-bottom: 15px;
    }
  }

  p {
    margin-bottom: 30px;
  }
}

.postern__params {
  margin-bottom: 60px;

  @media $small-mobile {
    display: flex;
    flex-direction: column;
  }
}

.postern__params-top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  margin-bottom: 100px;

  @media $handheld {
    grid-template-columns: auto;
  }

  @media $small-mobile {
    margin-bottom: 50px;
  }
}

.postern__params-bottom {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 100px;

  @media $handheld {
    grid-template-columns: auto auto;
  }

  @media $mobile {
    grid-template-columns: auto;
  }

  @media $small-mobile {
    order: 1;
    margin-bottom: 50px;
    gap: 50px;
  }
}

.postern__params-bottom__wrapper {
  p {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;

    @media $small-mobile {
      font-size: 15px;
    }
  }
}

.postern__params-text {
  max-width: 884px;
  font-size: 20px;
  margin-bottom: 100px;

  @media $small-mobile {
    margin-bottom: 50px;
  }

  span{
    margin-bottom: 20px;
    font-weight: 800;
    display: inline-block;

    @media $small-mobile {
      font-size: 15px;
    }
  }

  p {
    @media $small-mobile {
      font-size: 13px;
    }
  }

  p + P {
    margin-top: 20px;
  }
}

.postern__opening-images {
  display: grid;
  grid-template-columns: auto auto;
  gap: 50px;
  justify-content: space-between;
  margin-bottom: 200px;

  @media $mobile {
    grid-template-columns: auto;
  }

  @media $small-mobile {
    margin-bottom: 50px;
  }
}

.advantages__caption {
  font-size: 4rem;
  font-weight: $medium;
  margin-bottom: 60px;

  @media $mobile {
    font-size: 2rem;
    margin-bottom: 22px;
  }
}

.advantages__slider {
  margin-bottom: 90px;

  @media $mobile {
    margin-bottom: 22px;
  }

  .slick-list {
    padding: 0 60px;
    min-width: 0;
    margin-left: -76px;

    @media only screen and (max-width: 1367px) {
      margin-left: -100px;
      padding: 0 30px;
    }

    @media $handheld {
      padding: 0 20px;
    }

    @media $mobile {
      margin-left: -50px;
    }
  }

  .slick-track {
    min-width: 0;
  }
}

.advantages__slider-item {
  margin-left: 76px;

  @media only screen and (max-width: 1367px) {
    margin-left: 100px;
  }

  @media $mobile {
    margin-left: 50px;
  }
}

.advantages__slider-item__img {
  margin-bottom: 42px;

  img {
    box-shadow: 20px 20px 0px 1px #60544e;
    display: block;

    @media only screen and (max-width: 1367px) {
      box-shadow: 12px 12px 0px 1px #60544e;
    }
  }
}

.advantages__slider-item__text {
  color: #6d6e71;

  span {
    font-size: 2rem;
    margin-bottom: 40px;
    display: block;
  }

  p {
    line-height: normal;
  }
}

.advantages__slider-controls {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  @media $mobile {
    justify-content: center;
  }

  .slick-arrow {
    position: relative;
    transform: unset;
    flex-shrink: 0;
  }

  .slick-prev {
    margin-right: 20px;
  }
}

.advantages__slider-controls__line {
  order: 1;
  width: 100%;
  height: 1px;
  background-color: #acacac;
  margin-left: 35px;

  @media $mobile {
    display: none;
  }
}

.advantages__more {
  padding: 10px 86px 10px 20px;
  border: 0.6px solid $black;
  position: relative;
  border-radius: 20px;
  font-size: 1.3rem;
  display: table;
  margin: 0 auto;

  &::after {
    position: absolute;
    content: '';
    width: 32px;
    height: 6px;
    background: url('/local/templates/statusdoor/images/arrow-white-long.svg') 50% / contain no-repeat;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    filter: brightness(0) saturate(100%);
  }
}

/* stylelint-disable */
/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
/* stylelint-enable */
