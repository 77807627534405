/*   -=========== Print styles ===========-   */
@media print {
  * {
    background: transparent!important;
    color: #000000!important;
    box-shadow: none!important;
    text-shadow: none!important; }
  a, a:visited {
    text-decoration: underline; }
  // a[href]:after {
  //   content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  a[href^="javascript:"]:after, a[href^="#"]:after {
    content: ""; }
  pre, blockquote, tr, img, h1, h2, h3, h4 {
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  img {
    max-width: 100%!important; }
  @page {
    margin: 0.5cm; }
  p, h1, h2, h3, h4 {
    orphans: 3;
    widows: 3; }
  .noprint {
    display: none; }
}